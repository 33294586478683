import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { scaleTime } from 'd3-scale'
import { format, parseISO } from 'date-fns'
import React, {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Customized,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import { useGeneralInfoContext } from '../../GeneralInfoContext'
import { MajorEventType as ImportedMajorEventType } from '../../utils/sentimentAnalysis'
import { CustomTooltip } from '../SentimentChart'

// Update the EventMessage type
type EventMessage = {
  user: string
  message: string
  isContext: boolean
  isAfterContext: boolean
  timestamp: string
}

// Update the local MajorEventType
type MajorEventType = ImportedMajorEventType & {
  event_deep_dive: string
  timestamp_range: {
    start: string
    end: string
  }
  index_range: string
  event: string
}

interface SentimentChartDrillDownProps {
  chartData: any[]
  selectedEventIndex: number | null
}

const SentimentChartDrillDown: React.FC<SentimentChartDrillDownProps> = ({
  chartData,
  selectedEventIndex,
}) => {
  const theme = useTheme()
  const { users, parsedData } = useGeneralInfoContext()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [hoveredEvent, setHoveredEvent] = useState<number | null>(null)
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })
  const [isDragging, setIsDragging] = useState(false)
  const [hoverTooltip, setHoverTooltip] = useState<{
    show: boolean
    x: number
    y: number
    content: string
  }>({
    show: false,
    x: 0,
    y: 0,
    content: '',
  })
  const [selectedEventMessages, setSelectedEventMessages] = useState<
    EventMessage[]
  >([])
  const [isMajorEventTooltipActive, setIsMajorEventTooltipActive] =
    useState(false)
  const [isHoveringTooltip, setIsHoveringTooltip] = useState(false)
  const [hoveredEventIndex, setHoveredEventIndex] = useState<number | null>(
    null
  )
  const [showEvents, setShowEvents] = useState(true)

  const majorEvents = useMemo(() => {
    return chartData.flatMap((week) => week.major_events || [])
  }, [chartData])

  const [user1, user2] = users || []
  const user1Color = theme.palette.primary.main
  const user2Color = theme.palette.secondary.main

  const chartDataWithTimestamps = useMemo(() => {
    return chartData.map((dataPoint) => ({
      ...dataPoint,
      weekStartTimestamp: new Date(dataPoint.weekStart).getTime(),
    }))
  }, [chartData])

  const xScale = useMemo(() => {
    if (chartData.length === 0) return null

    // Extract the first and last dates from chartData
    const firstDataDate = new Date(chartData[0].weekStart)
    const lastDataDate = new Date(chartData[chartData.length - 1].weekStart)

    // Determine the latest event date
    const lastEventDate =
      majorEvents.length > 0
        ? new Date(
            Math.max(
              ...majorEvents.map((e) =>
                new Date(e.timestamp_range.end).getTime()
              )
            )
          )
        : lastDataDate

    // Choose the later date between lastDataDate and lastEventDate
    const domainEnd =
      lastEventDate > lastDataDate ? lastEventDate : lastDataDate

    // Optional: Add a buffer of 7 days to the domain end
    const bufferedDomainEnd = new Date(
      domainEnd.getTime() + 7 * 24 * 60 * 60 * 1000
    )

    return scaleTime()
      .domain([firstDataDate, bufferedDomainEnd]) // Updated domain
      .range([0, 1000]) // Adjust this value based on your chart width
  }, [chartData, majorEvents])

  const chartRef = useRef<HTMLDivElement>(null)

  const handleMouseDown = (e: ReactMouseEvent<HTMLDivElement>) => {
    setIsDragging(true)
  }

  const handleMouseMove = (e: ReactMouseEvent<HTMLDivElement>) => {
    if (isDragging) {
      setTooltipPosition((prev) => ({
        x: prev.x + e.movementX,
        y: prev.y + e.movementY,
      }))
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp as EventListener)
    return () => {
      document.removeEventListener('mouseup', handleMouseUp as EventListener)
    }
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectedEventMessages.length > 0) {
        const tooltip = document.getElementById('selected-event-messages')
        if (tooltip && !tooltip.contains(event.target as Node)) {
          setSelectedEventMessages([])
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside as EventListener)
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutside as EventListener
      )
    }
  }, [selectedEventMessages])

  // CustomEventMarkers Component
  type CustomEventMarkersProps = {
    xAxisMap: { [key: string]: any }
    majorEvents: MajorEventType[]
    theme: any
    setHoveredEvent: React.Dispatch<React.SetStateAction<number | null>>
    setHoverTooltip: React.Dispatch<
      React.SetStateAction<{
        show: boolean
        x: number
        y: number
        content: string
      }>
    >
    setIsMajorEventTooltipActive: React.Dispatch<React.SetStateAction<boolean>>
    setHoveredEventIndex: React.Dispatch<React.SetStateAction<number | null>>
    parsedData: any[] // Adjust type as necessary
    setSelectedEventMessages: React.Dispatch<
      React.SetStateAction<EventMessage[]>
    >
    isHoveringTooltip: boolean
  }

  const CustomEventMarkers: React.FC<CustomEventMarkersProps> = (props) => {
    const {
      xAxisMap,
      majorEvents,
      theme,
      setHoveredEvent,
      setHoverTooltip,
      setIsMajorEventTooltipActive,
      setHoveredEventIndex,
      parsedData,
      setSelectedEventMessages,
      isHoveringTooltip,
    } = props

    const xAxis = xAxisMap['0'] // Assuming a single X-axis
    const xScale = xAxis.scale

    const eventPositions: Array<{ x: number; y: number }> = []
    const yBasePosition = 20 // Base y position to position rect within top margin
    const xOffsetStep = 2 // Horizontal offset to prevent overlapping on X-axis
    const rectWidth = 10 // Width of the marker
    const rectHeight = 220 // Height of the marker

    // Get the last timestamp on the x-axis
    const lastTimestamp = xScale.domain()[1]

    return (
      <g>
        {majorEvents.map((event: MajorEventType, index: number) => {
          const eventTimestamp = new Date(event.timestamp_range.start).getTime()
          // If the event timestamp is after the last timestamp on the x-axis, use the last timestamp
          const adjustedTimestamp = Math.min(eventTimestamp, lastTimestamp)
          let xPosition = xScale(adjustedTimestamp)

          // Adjust for overlapping events by shifting horizontally
          while (
            eventPositions.some(
              (pos) => Math.abs(xPosition - pos.x) < rectWidth
            )
          ) {
            xPosition += xOffsetStep
          }
          eventPositions.push({ x: xPosition, y: yBasePosition })

          const words = event.event.split(' ')
          const maxWords = 5 // Limit to 5 words to prevent excessive height
          const truncatedWords = words.slice(0, maxWords)
          if (words.length > maxWords) {
            truncatedWords[maxWords - 1] += '...'
          }

          const isHovered = hoveredEventIndex === index
          const opacity = hoveredEventIndex === null || isHovered ? 0.5 : 0.2

          return (
            <g key={`event-marker-${index}`}>
              <rect
                x={xPosition}
                y={yBasePosition + 100} // Position rect within top margin
                width={rectWidth}
                height={rectHeight}
                fill={theme.palette.error.main}
                opacity={opacity}
                style={{ cursor: 'pointer' }}
                onMouseEnter={(e) => {
                  setHoveredEventIndex(index)
                  const rect = e.currentTarget.getBoundingClientRect()
                  const chartRect = e.currentTarget
                    .closest('svg')
                    ?.getBoundingClientRect()
                  if (chartRect) {
                    setHoveredEvent(index)
                    setHoverTooltip({
                      show: true,
                      x: rect.right - chartRect.left + 10, // Position tooltip to the right
                      y: rect.top - chartRect.top, // Align with the top of the rect
                      content: event.event,
                    })
                    setTooltipPosition({ x: 0, y: 0 })
                    setIsMajorEventTooltipActive(true)
                  }
                }}
                onMouseLeave={() => {
                  setHoveredEventIndex(null)
                  setTimeout(() => {
                    if (!isHoveringTooltip) {
                      setHoveredEvent(null)
                      setHoverTooltip({ show: false, x: 0, y: 0, content: '' })
                      setIsMajorEventTooltipActive(false)
                    }
                  }, 100)
                }}
                onClick={() => {
                  const [startIndex, endIndex] = event.index_range
                    .split(':')
                    .map(Number)
                  const contextStartIndex = Math.max(0, startIndex - 10)
                  const contextEndIndex = Math.min(
                    parsedData.length - 1,
                    endIndex + 10
                  )
                  const eventMessages: EventMessage[] = parsedData
                    .filter(
                      (message: any) =>
                        message.index >= contextStartIndex &&
                        message.index <= contextEndIndex
                    )
                    .map((message: any) => ({
                      user: message.user,
                      message: message.message,
                      isContext:
                        message.index < startIndex || message.index > endIndex,
                      isAfterContext: message.index > endIndex,
                      timestamp: message.date.toISOString(),
                    }))
                  setSelectedEventMessages(eventMessages)
                }}
              />
              {(hoveredEventIndex === null || isHovered) &&
                truncatedWords.map((word, wordIndex) => (
                  <text
                    key={`event-text-${index}-${wordIndex}`}
                    x={xPosition + rectWidth / 2}
                    y={yBasePosition + 30 + wordIndex * 12} // Updated y-coordinate
                    textAnchor="middle"
                    fill={theme.palette.text.primary}
                    fontSize={10}
                    fontWeight="bold"
                    opacity={isHovered ? 1 : 0.7}
                  >
                    {word}
                  </text>
                ))}
            </g>
          )
        })}
      </g>
    )
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        chartRef.current &&
        !chartRef.current.contains(event.target as Node)
      ) {
        setHoveredEvent(null)
        setHoverTooltip({ show: false, x: 0, y: 0, content: '' })
        setIsMajorEventTooltipActive(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (!xScale) return

    if (
      selectedEventIndex !== null &&
      selectedEventIndex >= 0 &&
      selectedEventIndex < majorEvents.length
    ) {
      const event = majorEvents[selectedEventIndex]
      const eventDate = new Date(event.timestamp_range.start).getTime()
      const xPosition = xScale(eventDate)
      const yPosition = 40 // Adjust this value as needed

      setHoveredEvent(selectedEventIndex)
      setHoverTooltip({
        show: true,
        x: xPosition,
        y: yPosition,
        content: event.event,
      })
      setTooltipPosition({ x: 0, y: 0 })
      setIsMajorEventTooltipActive(true)
    } else {
      setHoveredEvent(null)
      setHoverTooltip({ show: false, x: 0, y: 0, content: '' })
      setIsMajorEventTooltipActive(false)
    }
  }, [selectedEventIndex, majorEvents, xScale])

  if (!chartData || chartData.length === 0) {
    return <Typography>No data available for the chart.</Typography>
  }

  const CustomTooltipWrapper = (props: TooltipProps<number, string>) => {
    if (!props.active) {
      return null
    }
    if (showEvents && isMajorEventTooltipActive) {
      return null
    }
    return <CustomTooltip {...props} users={users} />
  }

  const handleToggleEvents = () => {
    setShowEvents(!showEvents)
    // Reset all event-related states when hiding events
    setHoveredEvent(null)
    setHoverTooltip({ show: false, x: 0, y: 0, content: '' })
    setIsMajorEventTooltipActive(false)
    setSelectedEventMessages([])
    setHoveredEventIndex(null)
    setIsHoveringTooltip(false)
  }

  return (
    <Box
      ref={chartRef}
      sx={{ height: '500px', width: '100%', position: 'relative' }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 2,
          bgcolor: theme.palette.grey[50],
          borderRadius: 2,
          mb: 4,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            mb: 2,
            position: 'relative',
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={4} />
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              <Typography
                variant="h5"
                component="h2"
                sx={{ fontWeight: 'bold' }}
              >
                Detailed Sentiment Analysis
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <Button variant="outlined" onClick={handleToggleEvents}>
                {showEvents ? 'Hide Events' : 'Show Events'}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            width: '100%',
            height: 'calc(100% - 40px)',
            position: 'relative',
          }}
        >
          <ResponsiveContainer
            width="100%"
            height="100%"
            style={{ overflow: 'visible' }}
          >
            <LineChart
              data={chartDataWithTimestamps}
              margin={{ top: 150, right: 30, left: 20, bottom: 10 }} // Increased top margin
            >
              <XAxis
                dataKey="weekStartTimestamp"
                type="number"
                domain={['dataMin', 'dataMax']}
                tickFormatter={(tickItem) =>
                  format(new Date(tickItem), 'MMM d, yyyy')
                }
                interval={(isMobile ? 2 : 1) * Math.ceil(chartData.length / 10)}
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              />
              <YAxis
                domain={[-10, 10]}
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              />
              <Tooltip content={<CustomTooltipWrapper />} />
              <Legend verticalAlign="bottom" height={36} />
              <Line
                type="monotoneX"
                dataKey="X_sentiment"
                name={user1}
                stroke={user1Color}
                activeDot={{ r: 8 }}
                strokeWidth={2}
              />
              <Line
                type="monotoneX"
                dataKey="Z_sentiment"
                name={user2}
                stroke={user2Color}
                activeDot={{ r: 8 }}
                strokeWidth={2}
              />
              {showEvents && (
                <Customized
                  component={(props: any) => (
                    <CustomEventMarkers
                      xAxisMap={props.xAxisMap}
                      majorEvents={majorEvents}
                      theme={theme}
                      setHoveredEvent={setHoveredEvent}
                      setHoverTooltip={setHoverTooltip}
                      setIsMajorEventTooltipActive={
                        setIsMajorEventTooltipActive
                      }
                      setHoveredEventIndex={setHoveredEventIndex}
                      parsedData={parsedData || []}
                      setSelectedEventMessages={setSelectedEventMessages}
                      isHoveringTooltip={isHoveringTooltip}
                    />
                  )}
                />
              )}
            </LineChart>
          </ResponsiveContainer>

          {/* Hover tooltip */}
          {showEvents && hoverTooltip.show && (
            <Box
              id="major-event-tooltip"
              sx={{
                position: 'absolute',
                left: `${hoverTooltip.x}px`,
                top: `${hoverTooltip.y}px`,
                width: '300px',
                maxHeight: '400px',
                overflow: 'auto',
                backgroundColor: 'white',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                zIndex: 1000,
                boxShadow: theme.shadows[5],
              }}
              onMouseEnter={() => {
                setIsHoveringTooltip(true)
                setIsMajorEventTooltipActive(true)
              }}
              onMouseLeave={() => {
                setIsHoveringTooltip(false)
                setTimeout(() => {
                  if (!isHoveringTooltip) {
                    setHoveredEvent(null)
                    setHoverTooltip({ show: false, x: 0, y: 0, content: '' })
                    setIsMajorEventTooltipActive(false)
                  }
                }, 100)
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                {hoverTooltip.content}
              </Typography>
              <Typography variant="caption" display="block" mb={1}>
                Date:{' '}
                {hoveredEvent !== null &&
                  format(
                    parseISO(majorEvents[hoveredEvent].timestamp_range.start),
                    'MMM d, yyyy'
                  )}
              </Typography>
              <Typography variant="body2" whiteSpace="pre-wrap">
                {hoveredEvent !== null &&
                  majorEvents[hoveredEvent].event_deep_dive}
              </Typography>
            </Box>
          )}

          {/* Selected event messages */}
          {showEvents && selectedEventMessages.length > 0 && (
            <Box
              id="selected-event-messages"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              sx={{
                position: 'absolute',
                top: 50 + tooltipPosition.y,
                left: tooltipPosition.x,
                width: '100%',
                maxHeight: '400px',
                overflow: 'auto',
                bgcolor: theme.palette.background.paper,
                p: 2,
                borderRadius: 2,
                boxShadow: theme.shadows[5],
                zIndex: 1000,
                cursor: isDragging ? 'grabbing' : 'grab',
              }}
            >
              <IconButton
                sx={{ position: 'absolute', top: 5, right: 5 }}
                onClick={() => setSelectedEventMessages([])}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              <Typography variant="h6" gutterBottom>
                Event Messages
              </Typography>
              {selectedEventMessages.map((message, index) => {
                const isUser1 = message.user === user1
                const baseColor = isUser1 ? user1Color : user2Color
                let backgroundColor

                if (message.isContext) {
                  if (message.isAfterContext) {
                    backgroundColor = alpha(baseColor, 0.2) // Darker shade for after-context
                  } else {
                    backgroundColor = alpha(baseColor, 0.1) // Lighter shade for before-context
                  }
                } else {
                  backgroundColor = baseColor // Full color for messages in the index range
                }

                return (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor,
                      p: 1,
                      mb: 1,
                      borderRadius: 1,
                      color: message.isContext ? 'inherit' : 'white',
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 0.5,
                      }}
                    >
                      <span style={{ fontWeight: 'bold' }}>{message.user}</span>
                      <span
                        style={{
                          fontSize: '0.8em',
                          color: message.isContext
                            ? theme.palette.text.secondary
                            : 'inherit',
                        }}
                      >
                        {format(
                          new Date(message.timestamp),
                          'MMM d, yyyy HH:mm:ss'
                        )}
                      </span>
                    </Typography>
                    <Typography variant="body2" component="p">
                      {message.message}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

export default SentimentChartDrillDown
