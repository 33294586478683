import { format, startOfWeek } from 'date-fns'
import { ChatMessage } from '../utils/types'

export type ChartData = {
  weekStart: string
  [key: string]: string | number
}

export type EngagementData = {
  chartData: ChartData[]
  persons: string[]
}

export const processEngagementData = async (
  parsedData: ChatMessage[]
): Promise<EngagementData> => {
  const weeklyData: { [key: string]: { [key: string]: number } } = {}
  const personSet: Set<string> = new Set()

  parsedData.forEach((message) => {
    const weekStart = format(startOfWeek(message.date), 'yyyy-MM-dd')
    const wordCount = message.message.split(/\s+/).length
    personSet.add(message.user)
    if (!weeklyData[weekStart]) {
      weeklyData[weekStart] = {}
    }
    weeklyData[weekStart][message.user] =
      (weeklyData[weekStart][message.user] || 0) + wordCount
  })

  const sortedWeeks = Object.keys(weeklyData).sort()
  const filledData = sortedWeeks.map((weekStart) => {
    const weekData: ChartData = { weekStart }
    personSet.forEach((person) => {
      weekData[person] = weeklyData[weekStart][person] || 0
    })
    return weekData
  })

  const result = { chartData: filledData, persons: Array.from(personSet) }
  return result
}
