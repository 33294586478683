import React, { useState } from 'react';
import { Box, Typography, Link } from '@mui/material';
import PrivacyPolicyModal from '../PrivacyPolicy';

const Footer: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#222',
        color: '#fff',
        padding: '12px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '10px',
      }}
    >
      <Link 
        href="mailto:contactus.mosaic@gmail.com" 
        color="inherit" 
        underline="hover"
        sx={{ fontSize: '0.875rem' }}
      >
        Reach out at: contactus.mosaic@gmail.com
      </Link>
      <Link
        component="button"
        color="inherit"
        underline="hover"
        onClick={handleOpenModal}
        sx={{ fontSize: '0.875rem' }}
      >
        Privacy Policy
      </Link>
      <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
        2024 Mosaic
      </Typography>
      <PrivacyPolicyModal open={isModalOpen} onClose={handleCloseModal} />
    </Box>
  );
};

export default Footer;