import OpenAI from "openai";
import { zodResponseFormat } from "openai/helpers/zod";
import { z } from "zod";
import { config } from "../config";
import { ChatMessage } from "../utils/types";

// OpenAI client setup
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

// Types and schemas
export interface AnalysisResult {
  finalPredictions: { [key: string]: string };
  gptDescriptions: { [key: string]: string };
  chunkCount: number;
  letterCounts: { [key: string]: { [key: string]: number } };
}

const MBTIResponse = z.object({
  user1_type: z.string(),
  user2_type: z.string(),
});

type MBTIResponseType = z.infer<typeof MBTIResponse>;

// Utility Functions
export const createAdaptiveChunks = (
  messages: ChatMessage[],
  maxChunkCount = 1000,
  minMessagesPerParticipant = 10
): [number, number][] => {
  const totalMessages = messages.length;
  const chunks: [number, number][] = [];

  let start = 0;
  while (start < totalMessages && chunks.length < maxChunkCount) {
    let end = start;
    const participantCounts: { [key: string]: number } = {};

    while (end < totalMessages) {
      const user = messages[end].user;
      participantCounts[user] = (participantCounts[user] || 0) + 1;
      end++;

      if (
        Object.values(participantCounts).every(
          (count) => count >= minMessagesPerParticipant
        )
      ) {
        chunks.push([start, end]);
        start = end;
        break;
      }
    }

    // If we've reached the end without finding a valid chunk, break the loop
    if (end === totalMessages) {
      break;
    }
  }

  return chunks;
};

const createPrompt = (
  messages: ChatMessage[],
  start: number,
  end: number,
  topUsers: string[]
): string => {
  let prompt =
    "Analyze the following WhatsApp conversation chunk and predict the MBTI personality types of the participants. Provide only the MBTI type for each participant:\n\n";

  for (let i = start; i < end; i++) {
    prompt += `${messages[i].user}: ${messages[i].message}\n`;
  }

  prompt += `\nBased on these messages, what are the likely MBTI types of each participant? Consider the following aspects:

1. E (Extraversion) vs I (Introversion):
   - E: Strengths: Energetic, sociable, expressive. Weaknesses: May be overwhelming, attention-seeking.
   - I: Strengths: Reflective, focused, independent. Weaknesses: May seem aloof, struggle with social assertiveness.

2. N (Intuition) vs S (Sensing):
   - N: Strengths: Imaginative, pattern-recognizing, future-oriented. Weaknesses: May overlook details, be unrealistic.
   - S: Strengths: Practical, detail-oriented, present-focused. Weaknesses: May resist change, struggle with abstract concepts.

3. T (Thinking) vs F (Feeling):
   - T: Strengths: Logical, objective, analytical. Weaknesses: May seem insensitive, struggle with emotional situations.
   - F: Strengths: Empathetic, value-driven, harmonious. Weaknesses: May be overly emotional, struggle with impartial decisions.

4. P (Perceiving) vs J (Judging):
   - P: Strengths: Flexible, adaptable, spontaneous. Weaknesses: May seem unfocused, struggle with deadlines.
   - J: Strengths: Organized, planful, decisive. Weaknesses: May be rigid, struggle with unexpected changes.

5. -T (Turbulent) vs -A (Assertive):
   - T: Strengths: Self-aware, perfectionistic, growth-oriented. Weaknesses: May be self-critical, prone to stress.
   - A: Strengths: Confident, stable, stress-resistant. Weaknesses: May be overconfident, overlook self-improvement.

The classification given to one person should have absolutely no effect on the classification of another. Format should be as follows:

{
  
  "user1_type": "5-letter MBTI type (formatted as ____-_)",
  "user2_type": "5-letter MBTI type (formatted as ____-_)",
}`;

  return prompt;
};

const countMbtiLetters = (mbtiList: string[]): { [key: string]: number } => {
  const letterCounts: { [key: string]: number } = {
    E: 0,
    I: 0,
    N: 0,
    S: 0,
    T: 0,
    F: 0,
    J: 0,
    P: 0,
    a: 0,
    t: 0,
  };

  mbtiList.forEach((mbti) => {
    let [type, variant] = mbti.split("-");

    // Ensure that variant is defined and set to a default value if not
    variant = variant ? variant.toLowerCase() : "";

    type.split("").forEach((letter) => {
      if (letter in letterCounts) {
        letterCounts[letter]++;
      }
    });

    // Only count 'a' or 't' if they exist
    if (variant === "a" || variant === "t") {
      letterCounts[variant]++;
    }
  });

  return letterCounts;
};

const determineFinalMbti = (letterCounts: {
  [key: string]: number;
}): string => {
  const pairs = [
    ["E", "I"],
    ["N", "S"],
    ["T", "F"],
    ["J", "P"],
    ["a", "t"],
  ];

  let finalMbti = "";

  pairs.forEach(([a, b]) => {
    if (letterCounts[a] > letterCounts[b]) {
      finalMbti += a.toUpperCase();
    } else if (letterCounts[b] > letterCounts[a]) {
      finalMbti += b.toUpperCase();
    } else {
      // If tied, choose randomly
      finalMbti += Math.random() < 0.5 ? a.toUpperCase() : b.toUpperCase();
    }
  });

  // Add the hyphen before the last character (a/t)
  return finalMbti.slice(0, 4) + "-" + finalMbti.slice(4).toLowerCase();
};

async function generateMBTIDescriptions(
  mbtiTypes: { [key: string]: string },
  chatHistories: { [key: string]: string }
): Promise<{ [key: string]: string }> {
  const usernames = Object.keys(mbtiTypes);
  try {
    const completion = await openai.chat.completions.create({
      model: config.textModel,
      messages: [
        {
          role: "system",
          content:
            "You are an AI assistant that provides humorous and insightful MBTI personality descriptions. Always respond in valid JSON format with keys for each username and values as string descriptions.",
        },
        {
          role: "user",
          content: `Based on the following chat histories, provide brief (4-6 sentences each) humorous (slightly unhinged and lean a bit dark, but nothing too inappropriate) and insightful descriptions for both users. Include key traits, strengths, and potential weaknesses of their MBTI types. The descriptions should reference each user's specific messages and behaviors and not be generic. Ensure that both descriptions are distinct in their words and analogies when describing each user, and that you generate COMPLETE SENTENCES FOR EACH USER. Absolutely 100% ensure that the descriptions for User1 and User2 are BOTH COMPLETE SENTENCES and that you don't truncate any of them.  
User 1 (${usernames[0]}, ${mbtiTypes[usernames[0]]}):
${chatHistories[usernames[0]].slice(-15000)}

User 2 (${usernames[1]}, ${mbtiTypes[usernames[1]]}):
${chatHistories[usernames[1]].slice(-15000)}

Provide the descriptions in the following JSON format:
{
  "usernames": ["${usernames[0]}", "${usernames[1]}"],
  "descriptions": [
    "Description for ${usernames[0]}",
    "Description for ${usernames[1]}"
  ]
}`,
        },
      ],
      max_tokens: 6000,
      temperature: 1,
    });

    const content = completion.choices[0].message.content || "";

    // Remove any markdown formatting
    const cleanedContent = content.replace(/```json\n|\n```/g, '');

    const parsedContent = JSON.parse(cleanedContent);

    // Validate that the parsed object has the expected structure
    if (
      Array.isArray(parsedContent.usernames) &&
      Array.isArray(parsedContent.descriptions) &&
      parsedContent.usernames.length === 2 &&
      parsedContent.descriptions.length === 2
    ) {
      return {
        [parsedContent.usernames[0]]: parsedContent.descriptions[0],
        [parsedContent.usernames[1]]: parsedContent.descriptions[1],
      };
    } else {
      throw new Error("Parsed JSON does not have the expected structure");
    }
  } catch (error) {
    console.error("Error generating MBTI descriptions:", error);
    return Object.fromEntries(
      Object.keys(mbtiTypes).map((username) => [
        username,
        "Error generating MBTI description.",
      ])
    );
  }
}

// Main analysis function
export const performMBTIAnalysis = async (
  parsedData: ChatMessage[]
): Promise<AnalysisResult> => {
  const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
  if (!apiKey) {
    throw new Error("OpenAI API key is not defined");
  }

  const allUsers = parsedData.reduce((acc, msg) => {
    acc[msg.user] = (acc[msg.user] || 0) + 1;
    return acc;
  }, {} as { [key: string]: number });

  const sortedUsers = Object.keys(allUsers).sort(
    (a, b) => allUsers[b] - allUsers[a]
  );

  const topUsers = sortedUsers.slice(0, 2);
  topUsers.sort();

  console.log("Top users:", topUsers);

  const chunks = createAdaptiveChunks(parsedData);
  console.log("Chunks created:", chunks);

  const predictions = await processChunks(chunks, parsedData, topUsers);
  console.log("Predictions:", predictions);

  const result = await calculateFinalPredictions(
    predictions,
    parsedData,
    topUsers
  );
  console.log("Final result:", result);

  return { ...result, chunkCount: chunks.length };
};

const processChunks = async (
  chunks: [number, number][],
  parsedData: ChatMessage[],
  topUsers: string[]
): Promise<MBTIResponseType[]> => {
  const chunkPredictions = await Promise.all(
    chunks.map(async (chunk) => {
      const [start, end] = chunk;
      const chunkData = parsedData.slice(start, end);

      // Ensure each user has at least 6 messages in the chunk
      const messageCounts = chunkData.reduce((counts, msg) => {
        counts[msg.user] = (counts[msg.user] || 0) + 1;
        return counts;
      }, {} as { [key: string]: number });

      if (!topUsers.every((user) => (messageCounts[user] || 0) >= 6)) {
        console.log(
          "Skipping chunk due to insufficient messages for each user"
        );
        return null;
      }

      const prompt = createPrompt(parsedData, start, end, topUsers);

      try {
        const response = await openai.beta.chat.completions.parse({
          model: config.textModel,
          messages: [
            {
              role: "system",
              content: "You are an MBTI expert analyzing chat conversations.",
            },
            { role: "user", content: prompt },
          ],
          response_format: zodResponseFormat(MBTIResponse, "mbtiResponse"),
          temperature: 0,
        });
        const result = response.choices[0].message.parsed;
        if (result) {
          console.group("MBTI Analysis Result");
          console.log(`${topUsers[0]}_type: ${result.user1_type}`);
          console.log(`${topUsers[1]}_type: ${result.user2_type}`);
          console.log("Chunk of chat used for analysis:");
          console.log(
            chunkData.map((msg) => `${msg.user}: ${msg.message}`).join("\n")
          );
          console.groupEnd();
        }
        return result;
      } catch (error) {
        console.error("Error processing chunk:", error);
        return null;
      }
    })
  );

  return chunkPredictions.filter(
    (prediction): prediction is MBTIResponseType => prediction !== null
  );
};

const calculateFinalPredictions = async (
  predictions: MBTIResponseType[],
  parsedData: ChatMessage[],
  topUsers: string[]
): Promise<Omit<AnalysisResult, "chunkCount">> => {
  const allPredictions: { [key: string]: string[] } = {
    [topUsers[0]]: [],
    [topUsers[1]]: [],
  };

  predictions.forEach((prediction) => {
    allPredictions[topUsers[0]].push(prediction.user1_type);
    allPredictions[topUsers[1]].push(prediction.user2_type);
  });

  console.log("All predictions:", allPredictions);

  const finalPredictions: { [key: string]: string } = {};
  const letterCounts: { [key: string]: { [key: string]: number } } = {};
  const chatHistories: { [key: string]: string } = {};

  for (const [participant, mbtiList] of Object.entries(allPredictions)) {
    const letterCount = countMbtiLetters(mbtiList);
    console.log(`Letter counts for ${participant}:`, letterCount);

    const mbtiType = determineFinalMbti(letterCount);
    letterCounts[participant] = letterCount;
    finalPredictions[participant] = mbtiType;

    chatHistories[participant] = parsedData
      .filter((msg) => msg.user === participant)
      .map((msg) => `${msg.user}: ${msg.message}`)
      .join("\n");
  }

  const gptDescriptions = await generateMBTIDescriptions(
    finalPredictions,
    chatHistories
  );

  console.log("Final predictions:", finalPredictions);
  console.log("Letter counts:", letterCounts);
  console.log("GPT descriptions:", gptDescriptions);

  const result = { finalPredictions, gptDescriptions, letterCounts };
  return result;
};
