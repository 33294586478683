import React, { useState } from 'react';
import { Box, Typography, Link } from '@mui/material';
import PrivacyPolicyModal from '../PrivacyPolicy';

const Privacy: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box sx={{ padding: 4, backgroundColor: '#f9f9f9', textAlign: 'center' }}>
      <Typography
        variant="h4"
        component="h2"
        sx={{
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 'bold',
          color: '#333',
          marginBottom: 2,
        }}
      >
        Privacy
      </Typography>
      <Typography
        variant="body1"
        sx={{
          maxWidth: '800px',
          marginX: 'auto',
          lineHeight: 1.6,
          color: '#555',
        }}
      >
        We at Mosaic take your privacy incredibly seriously. We use one of the most secure cloud platforms in the world to ensure data integrity. Read up more on how your data is used in our{' '}
        <Link
          component="button"
          variant="body1"
          onClick={handleOpenModal}
          sx={{
            color: 'primary.main',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          privacy policy
        </Link>
        .
      </Typography>

      <PrivacyPolicyModal open={isModalOpen} onClose={handleCloseModal} />
    </Box>
  );
};

export default Privacy;