import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format, parseISO } from "date-fns";
import React from "react";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  EngagementData,
  processEngagementData,
} from "../utils/engagementLogic";
import { useS3Fetcher } from "../utils/fetcher";

const Engagement: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { data: engagementData } = useS3Fetcher<EngagementData>(
    processEngagementData,
    `cache/engagement`
  );

  if (!engagementData) {
    return (
      <Box sx={{ textAlign: "center", py: 4 }}>
        <Typography variant="body1">Loading chart data...</Typography>
      </Box>
    );
  }
  const { chartData, persons } = engagementData;

  if (chartData.length === 0) {
    return (
      <Box sx={{ textAlign: "center", py: 4 }}>
        <Typography variant="body1">
          No data available for the chart.
        </Typography>
      </Box>
    );
  }

  const [user1, user2] = persons;
  const user1Color = theme.palette.primary.main;
  const user2Color = theme.palette.secondary.main;

  const formatXAxis = (tickItem: string) => {
    return format(parseISO(tickItem), "MMM d, yyyy");
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", textAlign: "center" }}
        >
          Engagement
        </Typography>
      </Box>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          bgcolor: theme.palette.grey[50],
          borderRadius: 2,
          mb: 4,
          height: theme.spacing(50),
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ flexGrow: 1, width: "100%", height: "100%" }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 10,
              }}
            >
              <XAxis
                dataKey="weekStart"
                tickFormatter={formatXAxis}
                interval={(isMobile ? 2 : 1) * Math.ceil(chartData.length / 10)}
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              />
              <YAxis
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.divider}`,
                }}
              />
              <Legend />
              <Line
                type="monotone"
                dataKey={user1}
                stroke={user1Color}
                activeDot={{ r: 8 }}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey={user2}
                stroke={user2Color}
                activeDot={{ r: 8 }}
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Paper>
    </Box>
  );
};

export default Engagement;
