import { Box, CircularProgress } from '@mui/material'
import { fetchAuthSession, signOut } from 'aws-amplify/auth'
import axios from 'axios' // Make sure to install axios if you haven't already
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import WelcomePopup from './Popups/WelcomePopup'
import CustomDrawer from './UserDashboard/CustomDrawer'
import Footer from './UserDashboard/Footer'
import Header from './UserDashboard/Header'
import MainContent from './UserDashboard/MainContent'
const LAMBDA_ENDPOINT =
  'https://wxa4lag1xj.execute-api.us-east-1.amazonaws.com/prod/auth'
interface Chat {
  id: string
  name: string
  date: string
}

const UserDashboard: React.FC = () => {
  console.log('Rendering UserDashboard')

  useEffect(() => {
    console.log('UserDashboard mounted')
    return () => {
      console.log('UserDashboard unmounted')
    }
  }, [])

  const [chats, setChats] = useState<Chat[]>([])
  const [user, setUser] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [welcomePopupOpen, setWelcomePopupOpen] = useState(false)
  const navigate = useNavigate()

  // print all the auth tokens
  useEffect(() => {
    async function fetchTokens() {
      const { tokens } = await fetchAuthSession()
      console.log('tokens', tokens)
    }
    fetchTokens()
  }, [])

  const loadUserData = useCallback(async () => {
    try {
      const { tokens } = await fetchAuthSession()
      if (!tokens || !tokens.accessToken) {
        console.log('No access token available')
        return
      }
      setUser(tokens.accessToken)
      // TODO: Fetch user's chats from the backend
      // const userChats = await fetchUserChats(tokens.accessToken.payload['cognito:username']);
      // setChats(userChats);

      // Temporary: Add some mock chats for demonstration
      setChats([
        { id: '1', name: 'Chat 1', date: new Date().toISOString() },
        { id: '2', name: 'Chat 2', date: new Date().toISOString() },
        { id: '3', name: 'Chat 3', date: new Date().toISOString() },
      ])
    } catch (error) {
      console.error('Error loading user data:', error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    loadUserData()
  }, [loadUserData])

  const handleUploadSuccess = (chatId: string) => {
    const newChat: Chat = {
      id: chatId,
      name: `Chat ${chats.length + 1}`,
      date: new Date().toISOString(),
    }
    setChats([...chats, newChat])
  }

  const handleSignOut = async () => {
    try {
      await signOut()
      localStorage.setItem('showWelcomePopup', 'true')
      navigate('/')
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setDrawerOpen(open)
    },
    []
  )

  const makeAuthenticatedRequest = async () => {
    try {
      const { tokens } = await fetchAuthSession()
      if (!tokens || !tokens.accessToken) {
        console.error('No access token available')
        return
      }

      console.log(`Bearer ${tokens.idToken?.toString()}`)

      const response = await axios.post(
        LAMBDA_ENDPOINT, // Replace with your actual API Gateway URL
        {}, // Add request body here if needed
        {
          headers: {
            Authorization: `Bearer ${tokens.idToken?.toString()}`,
          },
        }
      )

      console.log('Authenticated API response:', response.data)
      // Handle the response data as needed
    } catch (error) {
      console.error('Error making authenticated request:', error)
    }
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          background: `linear-gradient(135deg, #e0f2ff, #e6e0ff)`,
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: `linear-gradient(135deg, #e0f2ff, #e6e0ff)`,
      }}
    >
      <Header
        user={user}
        toggleDrawer={toggleDrawer}
        handleSignOut={handleSignOut}
      />
      <CustomDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      <MainContent chats={chats} handleUploadSuccess={handleUploadSuccess} />
      <Footer />
      <WelcomePopup
        open={welcomePopupOpen}
        onClose={() => setWelcomePopupOpen(false)}
      />
    </Box>
  )
}

export default UserDashboard
