import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { LogOut as LogOutIcon, Menu as MenuIcon } from "lucide-react";
import React from "react";

interface HeaderProps {
  user: any;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  handleSignOut: () => void;
}

const Header: React.FC<HeaderProps> = ({
  user,
  toggleDrawer,
  handleSignOut,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 2,
        backgroundColor: "white",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <IconButton
        onClick={toggleDrawer(true)}
        edge="start"
        color="inherit"
        aria-label="menu"
      >
        <MenuIcon />
      </IconButton>
      <Typography
        variant="h4"
        component="h1"
        sx={{
          fontWeight: "bold",
          background: `linear-gradient(45deg, #3B82F6, #00C4FF, #60A5FA)`,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontFamily: "'Comfortaa', sans-serif",
          flexGrow: 1,
          textAlign: "center",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        {user?.payload?.username}'s HomeBoard
      </Typography>
      <Button
        variant="outlined"
        onClick={handleSignOut}
        startIcon={<LogOutIcon />}
        sx={{
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          },
        }}
      >
        Sign Out
      </Button>
    </Box>
  );
};

export default Header;
