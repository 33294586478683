import { Box, Button, CircularProgress } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useGeneralInfoContext } from '../GeneralInfoContext'
import { useShareFunctionality } from './ShareIconButton' // Adjust the import path as needed

const ShareButton: React.FC = () => {
  const navigate = useNavigate()
  const { isLoading, shareAction, ShareModal } = useShareFunctionality()
  const { hash, file } = useGeneralInfoContext()

  const isSharedLink = hash && !file

  const handleClick = () => {
    if (isSharedLink) {
      navigate('/')
    } else {
      shareAction(hash, file)
    }
  }

  const handleShopClick = () => {
    navigate('/shop')
  }

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Button
        onClick={handleShopClick}
        variant="contained"
        color="secondary"
        sx={{
          margin: '20px 0',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: '#9c27b0',
          },
        }}
      >
        Mosaic Shop
      </Button>
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        disabled={isLoading && !isSharedLink}
        sx={{
          margin: '20px 0',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: '#45a049',
          },
        }}
      >
        {isSharedLink ? (
          'Analyze another chat'
        ) : isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          'Share your dashboard'
        )}
      </Button>
      {!isSharedLink && <ShareModal />}
    </Box>
  )
}

export default ShareButton
