import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, IconButton, Fade } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MoodStoryline from './MoodStoryline';
import PersonalityInsights from './PersonalityInsights';
import RelationshipDynamics from './RelationshipDynamics';

const MacBookContainer = () => {
  const [activeModule, setActiveModule] = useState(0);
  const [nextModule, setNextModule] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  const modules = [
    <MoodStoryline key="mood" />,
    <PersonalityInsights key="personality" />,
    <RelationshipDynamics key="relationship" />
  ];

  const changeModule = useCallback((direction = 1) => {
    setFadeIn(false);
    const next = (activeModule + direction + modules.length) % modules.length;
    setNextModule(next);
  }, [activeModule, modules.length]);

  const handlePrevious = () => changeModule(-1);

  useEffect(() => {
    if (!fadeIn) {
      const fadeOutTimer = setTimeout(() => {
        setActiveModule(nextModule);
        setFadeIn(true);
      }, 300); // Fade out duration
      return () => clearTimeout(fadeOutTimer);
    }
  }, [fadeIn, nextModule]);

  useEffect(() => {
    const interval = setInterval(() => {
      changeModule(1);
    }, 8000); // Change module every 8 seconds
    return () => clearInterval(interval);
  }, [changeModule]);

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '40px 20px' }}>
      <Box sx={{ display: 'flex', width: '100%', maxWidth: '1560px', alignItems: 'flex-start', gap: '40px' }}>
        {/* Left side content */}
        <Box sx={{ flex: '0 0 30%', maxWidth: '400px' }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Relationship Analysis Dashboard
          </Typography>

          <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
            Mood Storyline
          </Typography>
          <Typography variant="body1" paragraph>
            Track the emotional journey of your relationship over time. Identify patterns, peaks, and valleys in your shared experiences.
          </Typography>

          <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
            Personality Insights
          </Typography>
          <Typography variant="body1" paragraph>
            Gain deeper understanding of individual traits and how they complement or challenge each other within your relationship dynamics.
          </Typography>

          <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
            Symbolic Artwork
          </Typography>
          <Typography variant="body1" paragraph>
            An abstract artistic representation of your relationship dynamic that incorporates personal themes into a beautiful canvas, with a description to accompany it.
          </Typography>
        </Box>

        {/* MacBook display */}
        <Box sx={{ flex: '0 0 70%', maxWidth: '910px', position: 'relative' }}>
          <Box
            sx={{
              width: '100%',
              aspectRatio: '16 / 10',
              perspective: '1000px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                transform: 'rotateX(5deg) rotateY(-20deg)',
                transformStyle: 'preserve-3d',
              }}
            >
              {/* Screen */}
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  background: '#000',
                  borderRadius: '20px',
                  padding: '2%',
                  boxShadow: '0 10px 30px rgba(0,0,0,0.3)',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                {/* Notch */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '15%',
                    height: '2%',
                    background: '#000',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    zIndex: 10,
                  }}
                />
                {/* Screen Content */}
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    background: 'linear-gradient(135deg, #ff8a00, #e52e71, #2ebf91)',
                    borderRadius: '10px',
                    padding: '20px',
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  <Fade in={fadeIn} timeout={300}>
                    <Box sx={{ height: '100%', overflow: 'auto' }}>
                      {modules[activeModule]}
                    </Box>
                  </Fade>
                  {/* Change Module Button */}
                  <IconButton
                    onClick={handlePrevious}
                    sx={{
                      position: 'absolute',
                      left: 10,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      backgroundColor: 'rgba(255, 255, 255, 0.3)',
                      '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.5)' },
                    }}
                  >
                    <ArrowBackIosNewIcon />
                  </IconButton>
                  {/* Indicator Dots */}
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 10,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    {modules.map((_, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: 10,
                          height: 10,
                          borderRadius: '50%',
                          backgroundColor: index === activeModule ? 'white' : 'rgba(255, 255, 255, 0.5)',
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
              {/* MacBook Body */}
              <Box
                sx={{
                  width: '100%',
                  height: '5%',
                  background: 'linear-gradient(to bottom, #e2e2e2, #d1d1d1)',
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                  transformOrigin: 'top',
                  transform: 'rotateX(-5deg)',
                  boxShadow: '0 5px 20px rgba(0,0,0,0.2)',
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MacBookContainer;
