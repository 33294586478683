import {
  Box,
  Button,
  CircularProgress,
  Fade,
  styled,
  Typography,
  useTheme,
} from '@mui/material'
import { keyframes } from '@mui/material/styles'
import { FileIcon, UploadIcon } from 'lucide-react'
import React, { useRef, useState } from 'react'
import {
  withFileUploadAndParse,
  WithFileUploadAndParseProps,
} from '../FileUploadAndParse'
import SampleChatFile from '../SampleChatFile'
import { useNavigate } from 'react-router-dom'

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`
const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(59, 130, 246, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
  }
`
const animateCloud = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(calc(100vw + 100%)); }
`

const PageContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  overflow: 'hidden',
  backgroundImage: 'linear-gradient(45deg, #e6f7ff, #b3e0ff, #99d6ff, #e6f7ff)',
  backgroundSize: '400% 400%',
  animation: `${gradientAnimation} 15s ease infinite`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 5%',
  position: 'relative',
  zIndex: 0, // Add this to ensure other elements are behind the upload box

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '256px',
    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><defs><linearGradient id="bottomGradient" x1="0%" y1="100%" x2="0%" y2="0%"><stop offset="0%" stop-color="%234facfe" stop-opacity="0.7" /><stop offset="20%" stop-color="%234facfe" stop-opacity="0.5" /><stop offset="40%" stop-color="%2300f2fe" stop-opacity="0.3" /><stop offset="60%" stop-color="%2300f2fe" stop-opacity="0.2" /><stop offset="80%" stop-color="%2300f2fe" stop-opacity="0.1" /><stop offset="100%" stop-color="%2300f2fe" stop-opacity="0" /></linearGradient></defs><path fill="url(%23bottomGradient)" d="M0 50 Q 25 45, 50 50 T 100 50 V100 H0"><animate attributeName="d" dur="10s" repeatCount="indefinite" values="M0 50 Q 25 45, 50 50 T 100 50 V100 H0; M0 50 Q 25 55, 50 50 T 100 50 V100 H0; M0 50 Q 25 45, 50 50 T 100 50 V100 H0"/></path></svg>')`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    zIndex: 1, // Ensure the gradient is behind the upload box
  },

  '& > .cloud': {
    position: 'absolute',
    background: '#fff',
    backgroundImage: 'linear-gradient(top, #fff 5%, #f1f1f1 100%)',
    borderRadius: '100px',
    boxShadow: '0 8px 5px rgba(0, 0, 0, 0.1)',
    height: '120px',
    width: '350px',
    opacity: 0.8,
    zIndex: 2, // Ensure clouds are behind the upload box

    '&:after, &:before': {
      content: '""',
      position: 'absolute',
      background: '#fff',
      zIndex: -1,
    },

    '&:after': {
      borderRadius: '100px',
      height: '100px',
      left: '50px',
      top: '-50px',
      width: '100px',
    },

    '&:before': {
      borderRadius: '200px',
      width: '180px',
      height: '180px',
      right: '50px',
      top: '-90px',
    },
  },

  '& > .x1': {
    animation: `${animateCloud} 60s linear infinite`,
    transform: 'scale(0.65)',
    top: '10%',
    left: '-20%',
  },

  '& > .x2': {
    animation: `${animateCloud} 80s linear infinite`,
    transform: 'scale(0.4)',
    top: '25%',
    left: '-15%',
    animationDelay: '-20s',
  },

  '& > .x3': {
    animation: `${animateCloud} 100s linear infinite`,
    transform: 'scale(0.5)',
    top: '40%',
    left: '-30%',
    animationDelay: '-40s',
  },

  '& > .x4': {
    animation: `${animateCloud} 90s linear infinite`,
    transform: 'scale(0.7)',
    top: '60%',
    left: '-25%',
    animationDelay: '-15s',
  },

  '& > .x5': {
    animation: `${animateCloud} 120s linear infinite`,
    transform: 'scale(0.55)',
    top: '80%',
    left: '-10%',
    animationDelay: '-70s',
  },
}))
const ContentLeft = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  position: 'relative',
})

const ContentRight = styled(Box)({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 3, // Ensure the upload box is above other elements
})

const TitleWrapper = styled(Box)({
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '120%',
    height: '120%',
    background: 'rgba(255, 255, 255, 0.7)',
    filter: 'blur(30px)',
    zIndex: 1,
  },
})

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '7rem',
  fontWeight: 'bold',
  fontFamily: "'Comfortaa', sans-serif",
  background: `linear-gradient(90deg, ${theme.palette.primary.main}, #00C4FF, #60A5FA)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  lineHeight: 1.1,
  marginBottom: theme.spacing(2),
  position: 'relative',
  zIndex: 2,
}))

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 400,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(4),
  position: 'relative',
  zIndex: 2,
}))

const TryItOutButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: '10px 20px',
  fontSize: '1rem',
  fontWeight: 500,
  textTransform: 'none',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  zIndex: 2,
  animation: `${pulse} 2s infinite`,
}))

const SampleFileWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  marginTop: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'move',
  zIndex: 3, // Make sure the sample file wrapper is above other elements
}))

const DropZone = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '300px',
  width: '100%',
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'transparent',
  transition: theme.transitions.create(['border', 'background-color'], {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  padding: theme.spacing(4),
}))

const NonAuthenticatedUploadComponent: React.FC<
  WithFileUploadAndParseProps
> = ({ onFileUpload, isLoading, error, fileInputRef }) => {
  const theme = useTheme()
  const [isDragOver, setIsDragOver] = useState(false)

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragOver(false)
    const data = event.dataTransfer.getData('application/json')
    if (data) {
      try {
        const parsedData = JSON.parse(data)
        if (parsedData.isSampleFile && parsedData.file) {
          const sampleFile = new File([parsedData.file], 'SampleChat.txt', {
            type: 'text/plain',
          })
          onFileUpload(sampleFile)
          return
        }
      } catch (error) {
        console.error('Error parsing drag data:', error)
      }
    }
    const files = event.dataTransfer.files
    if (files && files.length > 0) {
      onFileUpload(files[0])
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragOver(true)
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragOver(false)
  }

  const handleButtonClick = () => {
    console.log('handleButtonClick')
    fileInputRef.current?.click()
  }

  return (
    <DropZone
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      sx={{
        backgroundColor: isDragOver
          ? theme.palette.action.hover
          : 'transparent',
      }}
    >
      <Typography variant="h6" color="text.primary" sx={{ mb: 2 }}>
        Analyze Your Chat File
      </Typography>
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      {isLoading ? (
        <CircularProgress sx={{ mb: 2 }} />
      ) : (
        <Button
          onClick={handleButtonClick}
          startIcon={<UploadIcon />}
          variant="contained"
          size="large"
          sx={{ mb: 2 }}
        >
          Select File
        </Button>
      )}
      <Typography variant="body2" color="text.secondary" align="center">
        {isLoading
          ? 'Processing your file...'
          : 'Drag and drop a file anywhere in this area,\nor click the button to select a file\n(Supports .txt, .zip, .json, and .db files)'}
      </Typography>
      <input
        ref={fileInputRef}
        type="file"
        accept=".txt,.zip,.json,.db"
        style={{ display: 'none' }}
        onChange={(e) => {
          console.log('onChange', e.target.files)
          e.target.files && onFileUpload(e.target.files[0])
        }}
      />
    </DropZone>
  )
}

const EnhancedUploadComponent = withFileUploadAndParse(
  NonAuthenticatedUploadComponent
)

const Header = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: theme.spacing(2),
  zIndex: 1000,
}))

const AuthButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  padding: '10px 20px',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  borderRadius: '50px',
  transition: 'all 0.3s ease',
}))

const SignInButton = styled(AuthButton)({
  color: '#000',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  border: '2px solid #000',
  '&:hover': {
    backgroundColor: '#000',
    color: '#fff',
  },
})

const SignUpButton = styled(AuthButton)({
  color: '#fff',
  backgroundColor: '#000',
  border: '2px solid #000',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
})

const MosaicLandingPage: React.FC = () => {
  const [sampleFileVisible, setSampleFileVisible] = useState(false)
  const dropZoneRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const handleTryItOutClick = () => {
    setSampleFileVisible(!sampleFileVisible)
  }

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    const sampleFile = SampleChatFile()
    e.dataTransfer.setData(
      'application/json',
      JSON.stringify({
        isSampleFile: true,
        file: sampleFile,
      })
    )
    e.dataTransfer.effectAllowed = 'copy'
  }

  const handleUploadSuccess = (chatId: string) => {
    console.log('Chat uploaded successfully:', chatId)
  }

  const handleSignIn = () => {
    navigate('/signin')
  }

  const handleSignUp = () => {
    navigate('/signup')
  }

  return (
    <PageContainer>
      <Header>
        <SignInButton onClick={handleSignIn}>
          Sign In
        </SignInButton>
        <SignUpButton onClick={handleSignUp}>
          Sign Up
        </SignUpButton>
      </Header>

      {/* Clouds are now integrated into the PageContainer */}
      <div className="cloud x1"></div>
      <div className="cloud x2"></div>
      <div className="cloud x3"></div>
      <div className="cloud x4"></div>
      <div className="cloud x5"></div>

      <ContentLeft>
        <Subtitle>Chat Insights by</Subtitle>
        <TitleWrapper>
          <Title>Mosaic</Title>
        </TitleWrapper>
        <Box sx={{ position: 'relative' }}>
          <TryItOutButton variant="contained" onClick={handleTryItOutClick}>
            {sampleFileVisible ? 'Reset' : 'TRY IT OUT'}
          </TryItOutButton>
          <Fade in={sampleFileVisible}>
            <SampleFileWrapper draggable onDragStart={handleDragStart}>
              <FileIcon size={24} style={{ marginBottom: '8px' }} />
              <Typography variant="body2">SampleChat.txt</Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{ mt: 1, textAlign: 'center' }}
              >
                Drag this file to the upload area
              </Typography>
            </SampleFileWrapper>
          </Fade>
        </Box>
      </ContentLeft>
      <ContentRight>
        <Box ref={dropZoneRef} sx={{ width: '100%', maxWidth: '700px' }}>
          <EnhancedUploadComponent onUploadSuccess={handleUploadSuccess} />
        </Box>
      </ContentRight>
    </PageContainer>
  )
}

export default MosaicLandingPage