import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useS3Fetcher } from '../utils/fetcher'
import {
  customImageLoader,
  customImageSaver,
  generateImage,
  generateImagePromptFromChat,
  ImageContent,
} from '../utils/imageGeneration'
import { ChatMessage } from '../utils/types'

const ChatImageGenerator: React.FC = () => {
  const theme = useTheme()
  const { data: imageContent } = useS3Fetcher<ImageContent>(
    generateImagePromptFromChat,
    'cache/image-prompt'
  )

  const { data: imageUrl } = useS3Fetcher<string>(
    (_: ChatMessage[]) => generateImage(imageContent?.imagePrompt || ''),
    'cache/image',
    customImageLoader,
    customImageSaver,
    !Boolean(imageContent?.imagePrompt)
  )

  return (
    <Box>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        sx={{ mb: 2, fontWeight: 'bold' }}
      >
        Chat-Inspired Artwork
      </Typography>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          bgcolor: theme.palette.grey[50],
          borderRadius: 2,
          mb: 4,
          minHeight: theme.spacing(50),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!imageUrl ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 2,
            }}
          >
            <Box sx={{ flex: 1, maxWidth: { xs: '100%', md: '60%' } }}>
              <img
                src={imageUrl || ''}
                alt="Generated from chat"
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '10px',
                }}
              />
            </Box>
            <Box sx={{ flex: 1, maxWidth: { xs: '100%', md: '40%' } }}>
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "'Dancing Script', cursive",
                  fontSize: { xs: '36px', md: '48px' },
                  fontWeight: 700,
                  color: theme.palette.text.primary,
                  textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
                  mb: 2,
                  borderBottom: `2px solid ${theme.palette.divider}`,
                }}
              >
                {imageContent?.title}
              </Typography>
              <Typography variant="body1" sx={{ fontStyle: 'italic', mb: 2 }}>
                {imageContent?.description}
              </Typography>
              <Paper
                elevation={1}
                sx={{
                  p: 2,
                  bgcolor: theme.palette.background.default,
                  borderRadius: 1,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "'Playfair Display', serif", mb: 1 }}
                >
                  Inspired Verse:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontStyle: 'italic', whiteSpace: 'pre-line' }}
                >
                  {imageContent?.poem}
                </Typography>
              </Paper>
            </Box>
          </Box>
        )}
      </Paper>
    </Box>
  )
}

export default ChatImageGenerator
