import { OpenAI } from 'openai'
import { zodResponseFormat } from 'openai/helpers/zod'
import { z } from 'zod'
import { ChatMessage } from '../utils/types'
import { uploadJsonToS3 } from './s3Storage'
import { config } from '../config'

const client = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
})

const SalientEvent = z.object({
  event: z.string(),
  salience: z.number(),
})

const QuoteWithIndex = z.object({
  index: z.number(),
})

const summaryQuote = z.object({
  index: z.number(),
})

const MajorEvent = z.object({
  event: z.string(),
  index_range: z.string(), // This will be a string in the format "start_index:end_index"
  timestamp_range: z.object({
    start: z.string(),
    end: z.string(),
  }),
  event_deep_dive: z.string(), // Add this line
})

const SentimentResponse = z.object({
  X_sentiment: z.number(),
  X_justification: z.string(),
  Z_sentiment: z.number(),
  Z_justification: z.string(),
  salient_events: z.array(SalientEvent),
  top_quotes: z.array(QuoteWithIndex),
  summary_quote: summaryQuote.optional(),
  major_events: z.array(MajorEvent),
})

export type SentimentResponseType = z.infer<typeof SentimentResponse>
export type QuoteWithIndexType = z.infer<typeof QuoteWithIndex>

export interface MajorEventType {
  event: string;
  event_deep_dive: string | object; // Adjusted to allow both string and object
  timestamp_range: {
    start: string;
    end: string;
  };
  index_range: string;
}

export const getSentiment = async (
  weekMessages: ChatMessage[],
  persons: string[]
): Promise<SentimentResponseType> => {
  const createMaskedConversation = (personIndex: number) => {
    return weekMessages
      .map((m) =>
        `${m.user === persons[personIndex] ? (personIndex === 0 ? 'X' : 'Z') : (personIndex === 0 ? 'Z' : 'X')}: ${m.user === persons[personIndex] ? m.message : ''} [${m.index}]`
      )
      .join('\n');
  };

  const maskedConversationX = createMaskedConversation(0);
  const maskedConversationZ = createMaskedConversation(1);

  console.log('Messages being fed into the model:', { maskedConversationX, maskedConversationZ });

  const prompt = `
    Firstly:
    Select at most 2 quotes from each user that are either completely unhinged or incredibly sweet and heartwarming.
    NOTE! The quotes are totally unrelated to the sentiment analysis subjects. Literally just find the craziest/funniest/stupidest chats. As unique and creative and cute as possible, not just straight nsfw. (No inventing stuff though!! If there's not enough content just don't include any quotes. I repeat, no inventing.) Once you have found the quote, return its index.

    Secondly:
    Rate the sentiment on a scale from -10 (extremely negative) to 10 (extremely positive). The rating should be based solely on the individual's sentiment, do not consider the other person's score when assigning a rating. For example: Do not consider person Z's messages when assigning a score to person X.
    Feel free to use up to 3 weeks before the current week as context for the events happening when determining what score to assign (for example if in the week they are discussing returning something in a neutral tone and you observe that in the previous weeks they broke up, this would be a rather negative sentiment score as opposed to if they were returning a sweatshirt because they had a really fun night in the week before which would be a positive sentiment). 
    Here are examples of events and their scores that you should use as anchors when determining what sentiment score to assign:
    10: Person X Just got married! They're over the moon with happiness saying that this is the best day of their lives!
    8: Person X is visiting Europe and is enjoying the time. They remark how it's quite chill and they could see themselves living there
    5: Person X had a nice day. They went bowling and their language indicates general contentment with life. They are looking forward to the future.
    2: Person X had a normal day. They remark how the weather is nice and discuss their favorite football players but nothing much to indicate that they are feeling any particularly strong emotion
    0: Person X had a day like every other. They are not particularly happy or sad and their messages are flat in emotion and engagement
    -2: Person X is generally detached in the conversation, slightly pessimistic about current events in their life
    -5: Person X did not have a good day. They had an argument with a coworker or something that they were looking forward to didn't work out as they expected
    -8: Person X got their laptop stolen/broke up with their significant other and are venting their feelings of frustration/anger at the situation
    -10: Person X's family member passed away and they are feeling very depressed
    Try to assign the score completely independently of a person's mannerism of speaking. For example a person can say "aww that's so sad" in a sarcastic manner, but that might indicate engagement in the convo and humor rather than actual sadness. Take this into account when creating your score
    For each sentiment score, provide a brief justification explaining why you chose that particular score.

    Also, identify important [influencing the sentiment] events or topics from the conversation, and rate their salience on a scale from 0 to 10. 0 is completely irrelevant, 10 is a dramatic life change, like getting married, 5 is like a party.
    Aim to include events with a salience of 5 or higher. This might be a completely different number of events depending on the week. There should always be at least one salient event connected to why the sentiment is assigned the value that it is. For example if the week was assigned a low sentiment, one of the salient events should be about what caused it.
    Finally, note that this is targeted at the users themselves, so assume they have all the context and make your responses match their style. Basically they just need to be reminded of what happened. You're talking to them directly. Be casual.

    Thirdly:
    Select ONE QUOTE that is maximally informative of what happened during the week. It should relate to what people are currently doing and be a sort of "summarizing weekly quote". Interesting quotes would relate to what the two people in the chat are currently doing,
    like if one says "I love being here in new york" it is informative of they are having fun as well as where they currently are. Aim to include quotes like this. ABSOLUTELY NO MAKING ANYTHING UP. This quote should not be one already in top_quotes. Remember to return the index of the quote instead of the quote itself.

    Fourthly:
    Identify major events in the chat. These should be big events like: first time meeting in person, passing of important person, high moments/achievments, difficult/low points, break-ups/big fights, reconciliations etc.
    If you come across such events in the chat, output a header for the event (example: Break Up), the range of message indexes which involve this event (should cover the entire context of the event, not just a single message), and an in depth overview of what happened in the event.
    For the in-depth overview, structure it in blocks for readability, covering aspects such as:
    1. Event Summary
    2. Key Interactions
    3. Emotional Responses
    4. Potential Impact on Relationship
    5. Important Considerations
    6. Psychoanalytical takeaways of each person
    Remember, it's okay not to find any such major events, in which case this field should be empty. Quality is more important than quantity.

    Chat transcript for X:
    ${maskedConversationX}

    Chat transcript for Z:
    ${maskedConversationZ}

    Provide your answer in the following JSON format:
    {
        "X_sentiment": number,
        "X_justification": "Brief explanation for X's sentiment score",
        "Z_sentiment": number,
        "Z_justification": "Brief explanation for Z's sentiment score",
        "salient_events": [
          {
            "event": "One sentence summary of an important event or topic",
            "salience": number
          },
          ... (4 max)
        ],
        "top_quotes": [
          {
            "index": number
          },
          ... (4 quotes total, 2 for each user)
        ],
        "summary_quote": {
          "index": number
        },
        "major_events": [
          {
            "event_header": "Brief header of the major event",
            "index_range": "start_index:end_index",
            "event_deep_dive": "
              Event Summary: ...

              Key Interactions: ...

              Emotional Responses: ...

              Potential Impact on Relationship: ...

              Important Considerations: ...

              Psychoanalytical Takeaway: ...
            "
          },
          ...
        ]
    }
  `

  const response = await client.beta.chat.completions.parse({
    model: config.textModel,
    messages: [
      {
        role: 'system',
        content: 'You are a helpful assistant that analyzes chat sentiment and identifies important events.',
      },
      { role: 'user', content: prompt },
    ],
    response_format: zodResponseFormat(SentimentResponse, 'sentimentResponse'),
    temperature: 0,
  });

  const message = response.choices[0]?.message;
  if (!message?.parsed) {
    throw new Error('Unexpected response from OpenAI API');
  }

  console.log('Model output:', JSON.stringify(message.parsed, null, 2));

  const quotes: { index: number; user: string; quote: string }[] = message.parsed.top_quotes
    .map((quote: QuoteWithIndexType) => {
      const actualMessage = weekMessages.find(m => m.index === quote.index);
      if (!actualMessage) {
        console.error(`Message not found for index ${quote.index}`);
        return null;
      }
      return {
        index: actualMessage.index,
        user: actualMessage.user,
        quote: actualMessage.message
      };
    })
    .filter((quote): quote is { index: number; user: string; quote: string } => quote !== null);

  console.log('Processed top quotes:', quotes);

  const summaryQuote = message.parsed?.summary_quote
  ? (() => {
      const actualMessage = weekMessages.find(m => m.index === message.parsed!.summary_quote!.index);
      if (!actualMessage) {
        console.error(`Summary quote message not found for index ${message.parsed!.summary_quote!.index}`);
        return null;
      }
      return {
        user: actualMessage.user,
        quote: actualMessage.message,
        index: actualMessage.index
      };
    })()
  : null;

  if (summaryQuote) {
    console.log('Summary quote:', summaryQuote);
  }

  const replacePersonIdentifiers = (text: string) => {
    return text.replace(/\bX\b/g, persons[0].split(' ')[0])
               .replace(/\bZ\b/g, persons[1].split(' ')[0]);
  };

  const salientEvents = message.parsed.salient_events.map((event) => ({
    ...event,
    event: replacePersonIdentifiers(event.event),
  }));

  const majorEvents: { event: string; index_range: string; timestamp_range: { start: string; end: string }; event_deep_dive: string }[] = 
  message.parsed.major_events.map((event) => {
    const [startIndex, endIndex] = event.index_range.split(':').map(Number);
    const startMessage = weekMessages.find(m => m.index === startIndex);
    const endMessage = weekMessages.find(m => m.index === endIndex);
    if (!startMessage || !endMessage) {
      console.error(`Start or end message not found for index range ${event.index_range}`);
      return null;
    }
    return {
      event: replacePersonIdentifiers(event.event),
      index_range: event.index_range,
      timestamp_range: {
        start: startMessage.date.toISOString(),
        end: endMessage.date.toISOString()
      },
      event_deep_dive: replacePersonIdentifiers(event.event_deep_dive) // Add this line
    };
  }).filter((event): event is { event: string; index_range: string; timestamp_range: { start: string; end: string }; event_deep_dive: string } => event !== null);

  console.log('Sentiment data:', {
    X_sentiment: message.parsed.X_sentiment,
    X_justification: replacePersonIdentifiers(message.parsed.X_justification),
    Z_sentiment: message.parsed.Z_sentiment,
    Z_justification: replacePersonIdentifiers(message.parsed.Z_justification),
    salient_events: salientEvents,
    top_quotes: quotes,
    summary_quote: summaryQuote,
    major_events: majorEvents,
  });

  return {
    X_sentiment: message.parsed.X_sentiment,
    X_justification: replacePersonIdentifiers(message.parsed.X_justification),
    Z_sentiment: message.parsed.Z_sentiment,
    Z_justification: replacePersonIdentifiers(message.parsed.Z_justification),
    salient_events: salientEvents,
    top_quotes: quotes,
    ...(summaryQuote && { summary_quote: summaryQuote }),
    major_events: majorEvents,
  };
}

export const fetchSentiments = async (
  weeklyData: { data: { [key: string]: ChatMessage[] }; persons: string[] },
  hash: string
) => {
  console.log('Entering fetchSentiments function');
  console.log('Number of weeks:', Object.keys(weeklyData.data).length);
  console.log('Persons:', weeklyData.persons);

  const allMajorEvents: { event: MajorEventType, messages: ChatMessage[] }[] = [];

  const sentimentPromises = Object.entries(weeklyData.data).map(
    async ([weekStart, messages]) => {
      console.log(`Processing week starting ${weekStart}`);
      console.log('Number of messages for this week:', messages.length);

      // Filter out invalid messages
      const validMessages = messages.filter((m, index) => {
        if (!m || typeof m !== 'object') {
          console.error(`Invalid message at index ${index} for week ${weekStart}:`, m);
          return false;
        }
        if (!m.message || typeof m.message !== 'string') {
          console.error(`Message without valid 'message' property at index ${index} for week ${weekStart}:`, m);
          return false;
        }
        return true;
      });

      console.log(`Number of valid messages for week ${weekStart}:`, validMessages.length);
      
      const words = validMessages.reduce(
        (acc, message) => acc + message.message.split(' ').length,
        0
      );
      
      if (words < 20) {
        console.log(`Skipping week ${weekStart} due to insufficient words (${words})`);
        return null;
      }

      try {
        const sentiment = await getSentiment(validMessages, weeklyData.persons);
        console.log(`Sentiment analysis completed for week ${weekStart}`);

        // Accumulate major events with their messages
        sentiment.major_events.forEach(event => {
          const [startIndex, endIndex] = event.index_range.split(':').map(Number);
          const startMessageIndex = validMessages.findIndex(m => m.index === startIndex);
          const endMessageIndex = validMessages.findIndex(m => m.index === endIndex);
          const eventMessages = validMessages.slice(
            startMessageIndex,
            endMessageIndex !== -1 ? endMessageIndex + 1 : undefined
          );
          allMajorEvents.push({ event, messages: eventMessages });
        });

        console.log(`Processed week ${weekStart}:`, sentiment);

        return {
          weekStart,
          messageCount: validMessages.length,
          messageIndices: validMessages.map(m => m.index),
          ...sentiment,
        };
      } catch (error) {
        console.error(`Error processing week ${weekStart}:`, error);
        return null;
      }
    }
  );

  let sentiments = await Promise.all(sentimentPromises);
  sentiments = sentiments
    .filter(Boolean)
    .sort((a, b) => a!.weekStart.localeCompare(b!.weekStart));

    console.log('Number of processed weeks:', sentiments.length);
    console.log('All Major Events:', allMajorEvents.length);
  
    // Log the major events and their messages
    allMajorEvents.forEach((event, index) => {
      console.log(`Major Event ${index + 1}:`, event.event.event);
      console.log('Index Range:', event.event.index_range);
      console.log('Timestamp Range:', event.event.timestamp_range);
      console.log('Event Deep Dive:', event.event.event_deep_dive);
      console.log('Messages:', event.messages.map(msg => `[${msg.date.toISOString()}] ${msg.user}: ${msg.message}`));
      console.log('Number of messages:', event.messages.length);
    });
  
    await uploadJsonToS3(`cache/sentiment/${hash}.json`, { sentiments, allMajorEvents });
    return { sentiments, allMajorEvents };
  };

export function calculateVolatility(data: number[]): number {
  if (data.length < 2) return 0;

  const changes = data.slice(1).map((value, index) => value - data[index]);
  const meanChange = changes.reduce((sum, change) => sum + change, 0) / changes.length;
  const squaredDifferences = changes.map(change => Math.pow(change - meanChange, 2));
  const variance = squaredDifferences.reduce((sum, sqDiff) => sum + sqDiff, 0) / (changes.length - 1);
  return Math.sqrt(variance);
}