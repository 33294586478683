import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useS3Fetcher } from "../utils/fetcher";
import { MBTILetter, traits } from "../utils/mbti";
import {
  AnalysisResult,
  performMBTIAnalysis,
} from "../utils/MBTIAnalysisLogic";

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(5),
  display: "flex",
  flexDirection: "column",
  alignItems: "space-between",
  width: "100%",
  height: "100%",
}));

const GradientBox = styled(Box)(({ theme }) => ({
  background: "linear-gradient(90deg, #8e2de2, #4a00e0)",
  color: theme.palette.common.white,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
  width: "100%",
}));

// Component for displaying MBTI letters
const LetterDisplay: React.FC<{
  letter: string;
  count: number;
  totalCount: number;
}> = ({ letter, count, totalCount }) => {
  const theme = useTheme();
  const percentage = (count / totalCount) * 100;
  const size = Math.max(20, Math.min(100, percentage));
  const color =
    percentage > 75 ? theme.palette.primary.main : theme.palette.text.secondary;

  return (
    <Tooltip title={`${letter}: ${percentage.toFixed(1)}%`} arrow>
      <Typography
        variant="h2"
        component="span"
        sx={{
          fontSize: `${size}px`,
          color: color,
          margin: "0 2px",
          fontWeight: percentage > 75 ? "bold" : "normal",
          cursor: "help",
        }}
      >
        {letter}
      </Typography>
    </Tooltip>
  );
};

// MBTI Analysis component
const MBTIAnalysis: React.FC = () => {
  const theme = useTheme();
  const { data: analysis, error } = useS3Fetcher<AnalysisResult>(
    performMBTIAnalysis,
    `cache/mbti`
  );

  const MBTIDisplay: React.FC<{
    userName: string;
    mbtiType: string; // Changed from MBTILetter to string
    letterCounts: { [key: string]: number };
    description: string;
    chunkCount: number;
  }> = ({ userName, mbtiType, letterCounts, description, chunkCount }) => {
    const renderLetterPair = (letter1: string, letter2: string) => {
      const count1 = letterCounts[letter1] || 0;
      const count2 = letterCounts[letter2] || 0;
      const totalCount = count1 + count2;

      const percentage1 = (count1 / totalCount) * 100;
      const percentage2 = (count2 / totalCount) * 100;

      if (percentage1 === 50 && percentage2 === 50) {
        return (
          <Tooltip title={`${letter1}: 50%, ${letter2}: 50%`} arrow>
            <Typography
              variant="h2"
              component="span"
              sx={{
                fontSize: "60px",
                color: theme.palette.text.secondary,
                margin: "0 2px",
                cursor: "help",
              }}
            >
              {`${letter1}/${letter2}`}
            </Typography>
          </Tooltip>
        );
      }

      return (
        <>
          {percentage1 > 50 && (
            <LetterDisplay
              letter={letter1}
              count={count1}
              totalCount={totalCount}
            />
          )}
          {percentage2 > 50 && (
            <LetterDisplay
              letter={letter2}
              count={count2}
              totalCount={totalCount}
            />
          )}
        </>
      );
    };

    const [baseType, variant] = mbtiType.split("-");
    const mbtiTypeKey = `${baseType}-${variant}` as MBTILetter;

    const renderVariant = (variant: string) => {
      const count = letterCounts[variant] || 0;
      const totalCount = letterCounts["a"] + letterCounts["t"];
      const percentage = (count / totalCount) * 100;
      const size = Math.max(20, Math.min(100, percentage));
      const color =
        percentage > 75
          ? theme.palette.primary.main
          : theme.palette.text.secondary;

      return (
        <Tooltip
          title={`${variant.toUpperCase()}: ${percentage.toFixed(1)}%`}
          arrow
        >
          <Box
            component="span"
            sx={{ display: "inline-flex", alignItems: "center" }}
          >
            <Typography
              variant="h2"
              component="span"
              sx={{
                fontSize: "20px", // Fixed size for the dash
                color: "black",
                margin: "0 2px",
                fontWeight: "normal", // Normal weight for the dash
                cursor: "help",
              }}
            >
              -
            </Typography>
            <Typography
              variant="h2"
              component="span"
              sx={{
                fontSize: `${size}px`,
                color: color,
                margin: "0 2px",
                fontWeight: percentage > 75 ? "bold" : "normal",
                cursor: "help",
              }}
            >
              {variant.toUpperCase()}
            </Typography>
          </Box>
        </Tooltip>
      );
    };

    return (
      <StyledPaper elevation={3}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {userName}
        </Typography>
        <Box
          sx={{
            fontSize: "6rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            mb: 2,
          }}
        >
          {renderLetterPair("E", "I")}
          {renderLetterPair("N", "S")}
          {renderLetterPair("T", "F")}
          {renderLetterPair("J", "P")}
          {renderVariant(variant)}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Personality strengths:</Typography>
            <List dense>
              {traits[mbtiTypeKey].strengths.map((strength, index) => (
                <ListItem key={index} disableGutters sx={{ py: 0.5 }}>
                  <ListItemText primary={strength} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Personality weaknesses:</Typography>
            <List dense>
              {traits[mbtiTypeKey].weaknesses.map((weakness, index) => (
                <ListItem key={index}>
                  <ListItemText primary={weakness} />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        <GradientBox>
          <Typography variant="body2">{description}</Typography>
        </GradientBox>
      </StyledPaper>
    );
  };

  if (!analysis) {
    return (
      <Box sx={{ textAlign: "center", py: 4 }}>
        <Typography variant="body1">Loading MBTI analysis...</Typography>
      </Box>
    );
  }
  if (error) {
    return (
      <Box sx={{ textAlign: "center", py: 4 }}>
        <Typography variant="body1">Error loading MBTI analysis.</Typography>
      </Box>
    );
  }
  if (!analysis) {
    return (
      <Box sx={{ textAlign: "center", py: 4 }}>
        <Typography variant="body1">
          No data available for the chart.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={3}>
        {Object.entries(analysis.finalPredictions).map(
          ([participant, mbtiType]) => (
            <Grid item xs={12} md={6} key={participant}>
              <MBTIDisplay
                userName={participant}
                mbtiType={mbtiType}
                letterCounts={analysis.letterCounts[participant]}
                description={
                  analysis.gptDescriptions[participant] ||
                  "Unable to generate profile."
                }
                chunkCount={analysis.chunkCount}
              />
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
};

export default MBTIAnalysis;
