import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ExportInstructions from '../ExportInstructions';

const WhatIsMosaic: React.FC = () => {
  return (
    <Box sx={{ padding: 4, paddingTop: 2 }}>
      {/* Title */}
      <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 'bold',
            color: '#333',
            letterSpacing: '0.05em',
          }}
        >
          What is Mosaic?
        </Typography>
      </Box>

      {/* Content Grid */}
      <Grid container spacing={4}>
        {/* Left Side - Export Instructions */}
        <Grid item xs={12} md={6}>
          <ExportInstructions />
        </Grid>
        {/* Right Side - Description and Additional Information */}
        <Grid item xs={12} md={6}>
          <Box>
            {/* What it does */}
            <Box sx={{ marginBottom: 3, textAlign: 'left' }}>
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#333',
                  marginBottom: 2,
                  textAlign: 'left' // Added this line
                }}
              >
                What We Do
              </Typography>
              <Typography
                variant="h6"
                component="p"
                sx={{
                  fontSize: '1.25rem',
                  lineHeight: 1.6,
                  color: '#555',
                }}
              >
                Mosaic analyzes chats and returns insights on engagement, sentiment, and personality between the two conversants. To use Mosaic, simply upload your chat file from any of the sources listed here.
              </Typography>
            </Box>

            {/* Current limitations */}
            <Box sx={{ marginBottom: 3, textAlign: 'left' }}>
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#333',
                  marginBottom: 2,
                  textAlign: 'left' // Added this line
                }}
              >
                Current Limitations
              </Typography>
              <Typography
                variant="h6"
                component="p"
                sx={{
                  fontSize: '1.25rem',
                  lineHeight: 1.6,
                  color: '#555',
                }}
              >
                Mosaic is currently desktop only, and designed for two person chats. As the application is in beta we appreciate your patience with any difficulties and/or changes that take place.
                 If you are having issues uploading chats or viewing certain analytical components, please contact us through our email listed at the bottom of the page.
              </Typography>
            </Box>

            {/* Try it out */}
            <Box sx={{ marginBottom: 3, textAlign: 'left' }}>
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#333',
                  marginBottom: 2,
                  textAlign: 'left' // Added this line
                }}
              >
                Try It Out
              </Typography>
              <Typography
                variant="h6"
                component="p"
                sx={{
                  fontSize: '1.25rem',
                  lineHeight: 1.6,
                  color: '#555',
                }}
              >
                To see what Mosaic can do without uploading one of your own chats, go to the top of the page and click the "Try it Out" button.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhatIsMosaic;
