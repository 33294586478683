import axios from 'axios'
import OpenAI from 'openai'
import { z } from 'zod'
import { config } from '../config'
import { ChatMessage } from '../utils/types'
import { checkFileExists, uploadUrlToS3 } from './s3Storage'
import { requestFile } from './s3cache'
const RUST_LAMBDA_ENDPOINT =
  'https://uc74b1oaw5.execute-api.us-east-1.amazonaws.com/prod/rust'

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true, // Note: This is not recommended for production use
})

const GPTResponseSchema = z.object({
  imagePrompt: z.string(),
  title: z.string(),
  description: z.string(),
  poem: z.string(),
  chatCategory: z.string(),
  suggestedStyles: z.array(z.string()).length(4),
})

export type ImageContent = z.infer<typeof GPTResponseSchema>

export async function generateImagePromptFromChat(
  parsedData: ChatMessage[]
): Promise<z.infer<typeof GPTResponseSchema>> {
  const chatText = parsedData
    .map((msg) => `${msg.user}: ${msg.message}`)
    .join('\n')

  const chatTextSlice = chatText.slice(-100000)

  const completion = await openai.chat.completions.create({
    model: config.textModel,
    messages: [
      {
        role: 'system',
        content:
          'You are an AI that analyzes chat conversations and generates creative content.',
      },
      {
        role: 'user',
        content: `Analyze the following chat conversation and provide:
        1. An image prompt that:
           - Incorporates the style of an artist that matches the vibe of the conversation. Avoid mentioning the artist's name because that gets blocked.
           - Results in an artsy image that subtly incorporates objects or themes from the chat.
           - Is recognizable to the chatters as inspired by their conversation, while still being somewhat abstract.
           - Image should be beautiful and memorable.
        2. A short, humorous title for the artwork that relates to the conversation's themes.
        3. A witty description from an overly serious art connoisseur's perspective that touches on themes from the chat. This should be recognizable to the people reading the description that it was inspired by their conversations.
        4. A brief poem (4-6 lines) that relates to the image and incorporates themes from the chat.
        5. A chat category that best describes the relationship between the participants (e.g., "relationship", "friendship", "bromance", "professional", etc.).
        6. A blend of four styles inspired by this conversation (e.g., "pop", "rap", "hip-hop", "electronic", etc.).

        Here's the chat:
        ${chatTextSlice}`,
      },
    ],
    functions: [
      {
        name: 'extract_image_info',
        parameters: {
          type: 'object',
          properties: {
            imagePrompt: {
              type: 'string',
              description: 'The prompt for generating the image',
            },
            title: {
              type: 'string',
              description: 'A humorous title for the artwork',
            },
            description: {
              type: 'string',
              description:
                'A witty description from an overly serious art connoisseur',
            },
            poem: {
              type: 'string',
              description: 'A brief poem related to the image and chat themes',
            },
            chatCategory: {
              type: 'string',
              description:
                'The category that best describes the relationship between the chat participants',
            },
            suggestedStyles: {
              type: 'array',
              items: {
                type: 'string',
              },
              minItems: 4,
              maxItems: 4,
              description:
                'Four suggested styles for a song inspired by the conversation',
            },
          },
          required: [
            'imagePrompt',
            'title',
            'description',
            'poem',
            'chatCategory',
            'suggestedStyles',
          ],
        },
      },
    ],
    function_call: { name: 'extract_image_info' },
  })

  const functionCall = completion.choices[0].message.function_call
  if (!functionCall || !functionCall.arguments) {
    throw new Error('No function call in GPT response')
  }

  return GPTResponseSchema.parse(JSON.parse(functionCall.arguments))
}

export async function generateImage(imagePrompt: string): Promise<string> {
  const imageResponse = await openai.images.generate({
    model: config.imageModel,
    prompt: imagePrompt,
    n: 1,
    size: '1024x1024',
    response_format: 'url',
  })

  return imageResponse.data[0]?.url || ''
}

export const customImageSaver = async (
  path: string,
  hash: string,
  result: string
) => {
  const desiredFilePath = `${path}/${hash}.png`
  await uploadUrlToS3(result, desiredFilePath, true)
  const response = await downsampleImage(desiredFilePath)
  console.log('downsample response', response)
}

export const customImageLoader = async (
  path: string,
  hash: string,
  token: string,
  refreshToken: () => Promise<string>
) => {
  const desiredFilePath = `${path}/${hash}.png`
  const exists = await checkFileExists(desiredFilePath)
  if (!exists) {
    return null
  }
  const imageUrl = await requestFile(
    desiredFilePath,
    hash,
    token,
    refreshToken,
    true
  )
  return imageUrl
}

export const downsampleImage = async (key: string): Promise<string> => {
  const response = await axios.post(RUST_LAMBDA_ENDPOINT, {
    action: 'downsample',
    key,
  })
  return response.data
}
