import { Amplify } from "aws-amplify";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: "us-east-1_HJcNN1vRB", // Replace with your actual User Pool ID
      userPoolClientId: "8gq8tgbslrrrbojq5dsjcimk2", // Replace with your actual App Client ID
    },
  },
});
