import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Home as HomeIcon, ShoppingBag, User } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

interface DrawerProps {
  drawerOpen: boolean;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const CustomDrawer: React.FC<DrawerProps> = ({ drawerOpen, toggleDrawer }) => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/");
    toggleDrawer(false)({} as React.MouseEvent);
  };

  return (
    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          <ListItem button onClick={handleHomeClick}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem
            button
            disabled
            onClick={() => navigate("/community-board")}
          >
            <ListItemIcon>
              <User />
            </ListItemIcon>
            <ListItemText primary="Community Board (Coming Soon)" />
          </ListItem>
          <ListItem button onClick={() => navigate("/shop")}>
            <ListItemIcon>
              <ShoppingBag />
            </ListItemIcon>
            <ListItemText primary="Shop" />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default CustomDrawer;
