import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import { MajorEventType } from '../../utils/sentimentAnalysis';
import { format, parseISO } from 'date-fns';

interface MajorEventsTableProps {
    majorEvents: any[];
    onEventClick: (event: any, index: number) => void;
    scrollToChart: () => void;
  }

const MajorEventsTable: React.FC<MajorEventsTableProps> = ({ majorEvents, onEventClick, scrollToChart }) => {
  console.log('MajorEventsTable received majorEvents:', majorEvents);

  const sortedEvents = useMemo(() => {
    return [...majorEvents].sort((a, b) => {
      const dateA = a.event?.timestamp_range?.start ? new Date(a.event.timestamp_range.start) : new Date(0);
      const dateB = b.event?.timestamp_range?.start ? new Date(b.event.timestamp_range.start) : new Date(0);
      return dateA.getTime() - dateB.getTime();
    });
  }, [majorEvents]);

  const renderDate = (event: any) => {
    if (event.event && event.event.timestamp_range && event.event.timestamp_range.start) {
      try {
        const date = format(parseISO(event.event.timestamp_range.start), 'MMM d, yyyy');
        return date;
      } catch (error) {
        console.error('Error formatting date:', error, event.event.timestamp_range.start);
        return 'Invalid date';
      }
    }
    return 'N/A';
  };

  const renderSummary = (event: any) => {
    if (event.event && typeof event.event.event_deep_dive === 'string') {
      const eventsummaryMatch = event.event.event_deep_dive.match(/Event Summary:\s*([\s\S]*?)(?:\r?\nKey Interactions:|$)/);
      if (eventsummaryMatch && eventsummaryMatch[1]) {
        return eventsummaryMatch[1].trim();
      }
    }
    return 'No summary available';
  };

  const renderEventName = (event: any) => {
    if (event.event && typeof event.event.event === 'string') {
      return event.event.event;
    }
    return 'Unknown Event';
  };

  const renderTableRows = () => {
    if (!Array.isArray(majorEvents) || majorEvents.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={6} align="center">No major events </TableCell>
        </TableRow>
      );
    }

    const rows = [];
    const midpoint = Math.ceil(sortedEvents.length / 2);

    for (let i = 0; i < midpoint; i++) {
      const leftEvent = sortedEvents[i];
      const rightEvent = sortedEvents[i + midpoint];

      rows.push(
        <TableRow key={i}>
          {/* Left Column Event */}
          <TableCell>{renderDate(leftEvent)}</TableCell>
          <TableCell 
            onClick={() => {
              onEventClick(leftEvent, sortedEvents.indexOf(leftEvent));
              scrollToChart();
            }} 
            sx={{ cursor: 'pointer', color: 'primary.main' }}
          >
            {renderEventName(leftEvent)}
          </TableCell>
          <TableCell>{renderSummary(leftEvent)}</TableCell>

          {/* Right Column Event (if exists) */}
          {rightEvent ? (
            <>
              <TableCell>{renderDate(rightEvent)}</TableCell>
              <TableCell 
                onClick={() => {
                  onEventClick(rightEvent, sortedEvents.indexOf(rightEvent));
                  scrollToChart();
                }} 
                sx={{ cursor: 'pointer', color: 'primary.main' }}
              >
                {renderEventName(rightEvent)}
              </TableCell>
              <TableCell>{renderSummary(rightEvent)}</TableCell>
            </>
          ) : (
            <>
              <TableCell colSpan={3}></TableCell>
            </>
          )}
        </TableRow>
      );
    }
    return rows;
  };

  return (
    <TableContainer component={Paper} sx={{ mb: 4 }}>
      <Typography variant="h6" align="center" sx={{ py: 2 }}>
        Major Events
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>Date</strong></TableCell>
            <TableCell><strong>Event</strong></TableCell>
            <TableCell><strong>Summary</strong></TableCell>
            <TableCell><strong>Date</strong></TableCell>
            <TableCell><strong>Event</strong></TableCell>
            <TableCell><strong>Summary</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableRows()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MajorEventsTable;