import React from 'react';
import { Box, Typography, Modal, Button, Fade, Backdrop } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import Confetti from 'react-confetti';

interface StorybookPopupProps {
  open: boolean;
  onClose: () => void;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(2),
  maxWidth: 700,
  margin: 'auto',
  marginTop: theme.spacing(10),
  textAlign: 'center',
  boxShadow: theme.shadows[5],
  animation: `${fadeIn} 0.5s ease-out`,
  position: 'relative',
  overflow: 'hidden',
}));

const DecorativeElement = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -50,
  right: -50,
  width: 100,
  height: 100,
  background: `url('/path/to/decorative-element.png') no-repeat center center`,
  backgroundSize: 'cover',
  opacity: 0.2,
}));

const StorybookPopup: React.FC<StorybookPopupProps> = ({ open, onClose }) => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      aria-labelledby="storybook-popup-title"
      aria-describedby="storybook-popup-description"
    >
      <Fade in={open}>
        <AnimatedBox>
          <DecorativeElement />
          <Typography
            id="storybook-popup-title"
            variant="h3"
            component="h2"
            gutterBottom
            sx={{
              fontFamily: 'Cursive, sans-serif',
              color: 'primary.main',
              textShadow: '2px 2px #f0e68c',
            }}
          >
            ✨ Your Personalized Storybook Awaits! ✨
          </Typography>
          <img
            src="/path/to/animated-storybook.gif"
            alt="Storybook Animation"
            style={{
              width: '80%',
              borderRadius: '12px',
              marginBottom: '20px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              transition: 'transform 0.3s',
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = 'scale(1.05)';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = 'scale(1)';
            }}
          />
          <Typography
            id="storybook-popup-description"
            variant="h6"
            sx={{
              mb: 3,
              fontStyle: 'italic',
              color: 'text.secondary',
            }}
          >
            Transform your cherished conversations into a beautifully illustrated storybook. Perfect as a heartfelt gift or a timeless keepsake.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            sx={{
              mt: 2,
              paddingX: 4,
              paddingY: 1.5,
              fontSize: '1rem',
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'background-color 0.3s, transform 0.3s',
              '&:hover': {
                backgroundColor: 'secondary.dark',
                transform: 'translateY(-2px)',
              },
            }}
          >
            🎉 Get Your Storybook Now 🎉
          </Button>
          {open && <Confetti width={width} height={height} recycle={false} />}
        </AnimatedBox>
      </Fade>
    </Modal>
  );
};

export default StorybookPopup;