import { fetchAuthSession } from 'aws-amplify/auth'
import axios, { AxiosError } from 'axios'
import { getHeaders } from './hashAuthentication'

const LAMBDA_ENDPOINT =
  'https://uc74b1oaw5.execute-api.us-east-1.amazonaws.com/prod/main'

const AUTH_LAMBDA_ENDPOINT =
  'https://wxa4lag1xj.execute-api.us-east-1.amazonaws.com/prod/auth'

export async function requestFileWithToken(
  token: string,
  refreshToken: () => Promise<string>,
  desiredFilePath: string,
  presignedUrl: boolean = false,
  doRefreshToken: boolean = true
): Promise<any> {
  const maxRetries = 3
  let retries = 0

  while (retries < maxRetries) {
    try {
      const response = await axios.post(LAMBDA_ENDPOINT, {
        action: 'request',
        token: token,
        requestedFilePath: desiredFilePath,
        presignedUrl,
      })

      if (response.data && response.data.requestedContent) {
        return response.data.requestedContent
      } else {
        throw new Error('File content not returned from Lambda')
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        if (axiosError.response) {
          if (axiosError.response.status === 401 && doRefreshToken) {
            const newToken = await refreshToken()
            if (!newToken) {
              throw new Error('Failed to refresh token')
            }
            // don't refresh again if we're already trying to refresh
            return requestFileWithToken(
              newToken,
              refreshToken,
              desiredFilePath,
              presignedUrl,
              false
            )
          }
          // If we have a response, it's an HTTP error, so we don't retry
          throw error
        }
      }

      retries++
      if (retries === maxRetries) {
        throw new Error(
          `Error requesting file with token after ${maxRetries} attempts: ${
            error instanceof Error ? error.message : 'Unknown error'
          }`
        )
      }
      // Wait for 1 second before retrying
      await new Promise((resolve) => setTimeout(resolve, 1000))
    }
  }
}

export async function requestFileWithAuth(
  hash: string,
  requestedFilePath: string,
  presignedUrl: boolean = false
): Promise<any> {
  const headers = await getHeaders(AUTH_LAMBDA_ENDPOINT)
  const response = await axios.post(
    AUTH_LAMBDA_ENDPOINT,
    {
      action: 'request',
      hash,
      requestedFilePath,
      presignedUrl,
    },
    {
      headers,
    }
  )
  return response.data.requestedContent
}

export async function requestFile(
  desiredFilePath: string,
  hash: string,
  token: string,
  refreshToken: () => Promise<string>,
  presignedUrl: boolean = false
) {
  const { tokens } = await fetchAuthSession()
  if (tokens) {
    return await requestFileWithAuth(hash, desiredFilePath, presignedUrl)
  } else {
    return await requestFileWithToken(
      token,
      refreshToken,
      desiredFilePath,
      presignedUrl
    )
  }
}
