import { createContext, ReactNode, useContext, useState } from 'react'
import { ChatMessage } from './utils/types'

interface GeneralInfoContextType {
  hash: string | null
  token: string | null
  file: File | null
  parsedData: ChatMessage[] | null
  users: string[] | null
  setHash: (hash: string) => void
  setToken: (token: string) => void
  setFile: (file: File) => void
  setParsedData: (parsedData: ChatMessage[] | null) => void
  setUsers: (users: string[]) => void
}

// Create the context with default values
const GeneralInfoContext = createContext<GeneralInfoContextType | undefined>(
  undefined
)

// Create a provider component
export const GeneralInfoProvider = ({ children }: { children: ReactNode }) => {
  const [parsedData, setParsedData] = useState<ChatMessage[] | null>(null)
  const [hash, setHash] = useState<string | null>(null)
  const [token, setToken] = useState<string | null>(null)
  const [file, setFile] = useState<File | null>(null)
  const [users, setUsers] = useState<string[] | null>(null)
  return (
    <GeneralInfoContext.Provider
      value={{
        hash,
        token,
        file,
        parsedData,
        users,
        setHash,
        setToken,
        setFile,
        setParsedData,
        setUsers,
      }}
    >
      {children}
    </GeneralInfoContext.Provider>
  )
}

// Create a hook to use the context in components
export const useGeneralInfoContext = () => {
  const context = useContext(GeneralInfoContext)
  if (context === undefined) {
    throw new Error(
      'useGeneralInfoContext must be used within an GeneralInfoProvider'
    )
  }
  return context
}
