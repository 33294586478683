import axios, { AxiosError } from 'axios'
import { getToken } from './hashAuthentication'

// Replace with your actual API Gateway endpoint
const LAMBDA_ENDPOINT =
  'https://uc74b1oaw5.execute-api.us-east-1.amazonaws.com/prod/rust'

// Function to call the API Gateway
export type ParseIMessageResponse = {
  presigned_url: string
}
export async function parseIMessage(
  key: string,
  file: File
): Promise<ParseIMessageResponse> {
  const maxRetries = 3
  let retries = 0
  const token = await getToken(key, file, 3600)
  while (retries < maxRetries) {
    try {
      const response = await axios.post(LAMBDA_ENDPOINT, {
        action: 'imessage',
        key,
        token,
      })
      if (response.data && response.data.presigned_url) {
        return response.data as ParseIMessageResponse
      } else {
        throw new Error('Presigned URL not returned from Lambda')
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        if (axiosError.response) {
          // If we have a response, it's an HTTP error, so we don't retry
          throw error
        }
      }

      retries++
      if (retries === maxRetries) {
        throw new Error(
          `Error parsing iMessage after ${maxRetries} attempts: ${
            error instanceof Error ? error.message : 'Unknown error'
          }`
        )
      }
      // Wait for 1 second before retrying
      await new Promise((resolve) => setTimeout(resolve, 1000))
    }
  }

  // This line is technically unreachable, but TypeScript might complain without it
  throw new Error('Unexpected error in parseIMessage')
}
