import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { Upload as UploadIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGeneralInfoContext } from "../../GeneralInfoContext";
import { getOwnedFiles } from "../../utils/hashAuthentication";
import { requestFile } from "../../utils/s3cache";
import { checkFileExists } from "../../utils/s3Storage";
import ExportInstructions from "../ExportInstructions";
import {
  withFileUploadAndParse,
  WithFileUploadAndParseProps,
} from "../FileUploadAndParse";

interface MainContentProps {
  chats: {
    id: string;
    name: string;
    date: string;
  }[];
  handleUploadSuccess: (chatId: string) => void;
}

const AuthenticatedUploadComponent: React.FC<WithFileUploadAndParseProps> = ({
  onFileUpload,
  isLoading,
  error,
  fileInputRef,
}) => {
  const theme = useTheme();

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      onFileUpload(files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Box
      onClick={() => fileInputRef.current?.click()}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      sx={{
        p: 4,
        backgroundColor: "transparent",
        border: `3px dotted ${theme.palette.primary.main}`,
        borderRadius: 2,
        textAlign: "center",
        cursor: "pointer",
        transition: "all 0.3s ease",
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 200,
        width: "100%",
        maxWidth: 600,
        margin: "0 auto",
      }}
    >
      <input
        type="file"
        ref={fileInputRef}
        onChange={(e) => e.target.files && onFileUpload(e.target.files[0])}
        style={{ display: "none" }}
        accept=".txt,.zip,.json,.db"
      />
      {isLoading ? (
        <Typography variant="h6">Uploading...</Typography>
      ) : (
        <>
          <UploadIcon
            size={48}
            color={theme.palette.primary.main}
            style={{ marginBottom: 16 }}
          />
          <Typography variant="h6" gutterBottom>
            Click or drag file to upload
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Supports .txt, .zip, .json, and .db files
          </Typography>
          {error && (
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
const EnhancedUploadComponent = withFileUploadAndParse(
  AuthenticatedUploadComponent
);

type ChatImage = {
  imageUrl: string;
  hash: string;
};

const MainContent: React.FC<MainContentProps> = ({
  chats,
  handleUploadSuccess,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [chatImages, setChatImages] = useState<ChatImage[]>([]);
  const { setHash, setToken } = useGeneralInfoContext();
  const [showExportInstructions, setShowExportInstructions] = useState(false);

  const toggleExportInstructions = () => {
    setShowExportInstructions(!showExportInstructions);
  };

  useEffect(() => {
    async function getChatImages() {
      const ownedFileHashes = await getOwnedFiles();
      const chatImages = await Promise.all(
        ownedFileHashes.map(async (hash) => {
          const exists = await checkFileExists(`cache/image/${hash}-small.png`);
          const path = exists
            ? `cache/image/${hash}-small.png`
            : `cache/image/${hash}.png`;
          const imageUrl = await requestFile(
            path,
            hash,
            "fake token",
            () => Promise.resolve("fake token"),
            true
          );
          return {
            imageUrl,
            hash,
          };
        })
      );
      setChatImages(chatImages);
    }
    getChatImages();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ flexGrow: 1, py: 4 }}>
      {/* Placeholder Boxes */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              p: 3,
              backgroundColor: "white",
              borderRadius: 2,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">Number of Messages Sent</Typography>
            <Typography variant="h4">1,234</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              p: 3,
              backgroundColor: "white",
              borderRadius: 2,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">Major Events</Typography>
            <Typography variant="h4">56</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              p: 3,
              backgroundColor: "white",
              borderRadius: 2,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">Conversations</Typography>
            <Typography variant="h4">78</Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Global Sentiment Chart Placeholder */}
      <Box
        sx={{
          mb: 4,
          p: 3,
          backgroundColor: "white",
          borderRadius: 2,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
          height: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Global Sentiment Chart Coming Soon</Typography>
      </Box>

      {/* File Upload Area */}
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h5"
          component="h2"
          sx={{ mb: 2, textAlign: "center" }}
        >
          Upload a New Chat
        </Typography>
        <EnhancedUploadComponent onUploadSuccess={handleUploadSuccess} />
      </Box>

      {/* Export Instructions */}
      <Box sx={{ mb: 4, textAlign: "center" }}>
        <Button
          variant="text"
          color="primary"
          onClick={toggleExportInstructions}
          sx={{
            p: 0,
            minWidth: "auto",
            textTransform: "none",
            fontSize: "0.875rem",
            "&:hover": {
              backgroundColor: "transparent",
              textDecoration: "underline",
            },
          }}
        >
          How to export
        </Button>
        {showExportInstructions && <ExportInstructions />}
      </Box>

      {/* Chat Squares */}
      <Typography
        variant="h5"
        component="h2"
        sx={{ mb: 2, color: theme.palette.text.primary, textAlign: "center" }}
      >
        Your Chats
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {chatImages.map((chatImage) => (
          <Grid item key={chatImage.hash}>
            <Box
              sx={{
                width: 160,
                height: 160,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
                borderRadius: 2,
                cursor: "pointer",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: theme.shadows[4],
                },
                background: "white",
              }}
              onClick={() => {
                setHash(chatImage.hash);
                setToken("fake");
                navigate(`/main`);
              }}
            >
              <img
                src={chatImage.imageUrl}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="chat"
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default MainContent;
