import { Box, Typography } from "@mui/material";
import React from "react";

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        mt: "auto",
        py: 2,
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        textAlign: "center",
      }}
    >
      <Typography variant="body2" color="text.secondary">
        Help | Settings | Privacy Policy
      </Typography>
    </Box>
  );
};

export default Footer;
