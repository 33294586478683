import Anthropic from "@anthropic-ai/sdk";
import axios, { AxiosError } from "axios";
import OpenAI from "openai";
import { z } from "zod";
import { requestFileWithToken } from "./s3cache";
import { checkFileExists, uploadBlobToS3, uploadJsonToS3 } from "./s3Storage";
import { ChatMessage } from "./types";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true, // Note: This is not recommended for production use
});

const anthropic = new Anthropic({
  apiKey: process.env.REACT_APP_ANTHROPIC_API_KEY,
  dangerouslyAllowBrowser: true, // Note: This is not recommended for production use
});

const SongResponseSchema = z.object({
  prompt: z.string(),
  tags: z.string(),
  title: z.string(),
  make_instrumental: z.boolean(),
  wait_audio: z.boolean(),
});

export type Song = {
  audioUrl: string;
  imageUrl?: string;
  title: string;
};

export type SongContent = {
  songs: Song[];
  lyrics: string;
};

const generatePrompt = (
  chatText: string,
  category: string,
  styles: string[],
  inspiration: string
): string => {
  return `Create personalized song lyrics based on the given chat. The lyrics should reflect the chat's ${category} nature, in a ${styles.join(
    ", "
  )} style and inspired by ${inspiration}. 
  The lyrics should capture key elements, themes, and moments from the chat, making it recognizable to the people involved if they were to hear it. Format the output to include sections like verses, choruses, bridges, and outros using line breaks (e.g., /n) to indicate spacing, but don't constrain to a specific structure (end song with [End] tag). 
  Remember the song is meant to be around 1 minute 30 seconds in length and doesn't include anything NSFW. Include tags relevent to lyrics, styles, and especially the inspiration.

Output Format:
{
  "prompt": "[Verse 1]\\n[Insert first verse here]\\n\\n[Chorus]\\n[Insert chorus here]\\n\\n[Bridge]\\n[Insert bridge here]\\n\\n[Chorus]\\n[Repeat chorus]" (Use this sort of syntax for song creation),
  "tags": "tag1, tag2, ...", // (song tags that should include the ones listed above. This is where you control the rhythm/style of music.)
  "title": "Insert song title here",
  "make_instrumental": false,
  "wait_audio": true
}

Here's the chat:
${chatText}

IF THE CHAT CONTAINS THEMES THAT YOU WOULD PREFER TO AVOID, DO NOT GO INTO DETAIL ABOUT THEM. CHOOSE YOUR OWN OUTPUTS BUT THE LYRICS SHOULD STILL BE RECOGNIZABLE AS INSPIRED BY THE CHAT.
`;
};

const generateSongLyrics = async (
  chatText: string,
  category: string,
  styles: string[],
  inspiration: string
): Promise<z.infer<typeof SongResponseSchema>> => {
  console.log("Generating song lyrics with GPT...");
  console.log("Input to GPT:", {
    chatText,
    category,
    styles,
    inspiration,
  });

  const completion = await openai.chat.completions.create({
    model: "gpt-4o",
    messages: [
      {
        role: "system",
        content:
          "You are an AI that analyzes chat conversations and generates creative song lyrics.",
      },
      {
        role: "user",
        content: generatePrompt(chatText, category, styles, inspiration),
      },
    ],
    functions: [
      {
        name: "generate_song",
        parameters: {
          type: "object",
          properties: {
            prompt: {
              type: "string",
              description: "The generated song lyrics",
            },
            tags: {
              type: "string",
              description: "Comma-separated tags for the song",
            },
            title: {
              type: "string",
              description: "The title of the song",
            },
            make_instrumental: {
              type: "boolean",
              description: "Whether to make an instrumental version",
            },
            wait_audio: {
              type: "boolean",
              description: "Whether to wait for audio generation",
            },
          },
          required: [
            "prompt",
            "tags",
            "title",
            "make_instrumental",
            "wait_audio",
          ],
        },
      },
    ],
    function_call: { name: "generate_song" },
  });

  const functionCall = completion.choices[0].message.function_call;
  if (!functionCall || !functionCall.arguments) {
    throw new Error("No function call in GPT response");
  }

  console.log("GPT Response:", functionCall.arguments);
  return SongResponseSchema.parse(JSON.parse(functionCall.arguments));
};

export const callAIML = async (
  songResponse: z.infer<typeof SongResponseSchema>
) => {
  console.log("Calling AIML");
  console.log("Input to AIML:", {
    prompt: songResponse.prompt,
    tags: songResponse.tags,
    title: songResponse.title,
    make_instrumental: songResponse.make_instrumental,
    wait_audio: songResponse.wait_audio,
  });

  const url = "https://api.aimlapi.com/generate/custom-mode";
  const headers = {
    Authorization: "Bearer 07f89e3707da42109fefaa6b624aebcf",
    "Content-Type": "application/json",
  };

  songResponse.prompt = songResponse.prompt.slice(0, 1200);
  songResponse.wait_audio = false;

  const response = await axios.post(url, songResponse, {
    headers,
    validateStatus: (status) => status < 500, // Consider all non-5xx statuses as resolved
  });
  console.log("AIML Response:", response.data);

  // Add a 10-second delay before checking the results
  await new Promise((resolve) => setTimeout(resolve, 10000));

  let results = [];
  const maxRetries = 5;
  const baseDelay = 5000; // 5 seconds

  const ids = response.data.map((item: any) => item.id);
  let baseUrl = "https://api.aimlapi.com/?ids=";
  let urls = ids.map((id: string) => `${baseUrl}${id}`);
  console.log("urls", urls);
  for (let url of urls) {
    let retries = 0;
    while (retries < maxRetries) {
      const response = await axios.get(url, { headers });
      if (response.data[0].audio_url) {
        results.push(response.data[0]);
        break;
      }
      const delay = baseDelay * Math.pow(2, retries);
      console.log(`Retrying in ${delay}ms...`);
      await new Promise((resolve) => setTimeout(resolve, delay));
      retries++;
    }
  }
  return results;
};

export const generateSongAndContent = async (
  parsedData: ChatMessage[],
  category: string,
  styles: string[],
  inspiration: string,
  onProgress: (step: number, progress: number) => void
): Promise<SongContent> => {
  console.log("Starting song generation process...");
  onProgress(0, 0);
  const chatText = parsedData
    .map((msg) => `${msg.user}: ${msg.message}`)
    .join("\n");

  onProgress(0, 50);
  const songResponse = await generateSongLyrics(
    chatText,
    category,
    styles,
    inspiration
  );
  console.log("Song response:", songResponse);
  onProgress(0, 100);
  try {
    onProgress(1, 100);

    const result = await callAIML(songResponse);
    console.log("AIML result:", result);
    if (result.length >= 2 && result[0].audio_url && result[1].audio_url) {
      onProgress(2, 50);
      const songContent: SongContent = {
        songs: [
          {
            audioUrl: result[0].audio_url,
            imageUrl: result[0].image_url,
            title: `${songResponse.title} - Version 1`,
          },
          {
            audioUrl: result[1].audio_url,
            imageUrl: result[1].image_url,
            title: `${songResponse.title} - Version 2`,
          },
        ],
        lyrics: songResponse.prompt,
      };
      onProgress(2, 100);
      return songContent;
    } else {
      throw new Error("Not enough audio URLs found in the response");
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      console.error(
        "Axios error:",
        axiosError.message,
        axiosError.response?.data
      );
      throw new Error(`Song generation failed: ${axiosError.message}`);
    } else {
      console.error("Unknown error:", error);
      throw new Error("An unknown error occurred during song generation");
    }
  }
};

export const customSongLoader = async (
  path: string,
  hash: string,
  token: string,
  refreshToken: () => Promise<string>
) => {
  const exists = await checkFileExists(`${path}/${hash}.json`);
  if (!exists) {
    return null;
  }
  const response = (await requestFileWithToken(
    token,
    refreshToken,
    `${path}/${hash}.json`
  )) as SongContent;
  const filesExist = await Promise.all([
    checkFileExists(`cache/suno/mp3s/${hash}-1.mp3`),
    checkFileExists(`cache/suno/mp3s/${hash}-2.mp3`),
    checkFileExists(`cache/suno/images/${hash}-1.png`),
    checkFileExists(`cache/suno/images/${hash}-2.png`),
  ]);
  // request presigned urls for the files that do exist in s3
  const urls = await Promise.all(
    filesExist.map((exists, index) => {
      const fileType = index < 2 ? "mp3s" : "images";
      const extension = index < 2 ? "mp3" : "png";
      if (exists) {
        return requestFileWithToken(
          token,
          refreshToken,
          `cache/suno/${fileType}/${hash}-${(index % 2) + 1}.${extension}`,
          true
        );
      }
      return null;
    })
  );
  // replace the audio urls with the presigned urls
  response.songs.forEach((song, index) => {
    song.audioUrl = urls[index] || song.audioUrl;
    song.imageUrl = urls[index + 2] || song.imageUrl;
  });
  return response;
};

export const customSongSaver = async (
  path: string,
  hash: string,
  result: SongContent
) => {
  await Promise.all([
    uploadJsonToS3(`${path}/${hash}.json`, result),
    downloadAudio(result, hash),
  ]);
};

export const downloadAudio = async (songContent: SongContent, hash: string) => {
  const audio = await Promise.all([
    fetch(songContent.songs[0].audioUrl),
    fetch(songContent.songs[1].audioUrl),
  ]);
  const audioBlobs = await Promise.all(audio.map((audio) => audio.blob()));
  const imageBlobs = await Promise.all(
    songContent.songs.map((song) => {
      if (song.imageUrl) {
        return fetch(song.imageUrl).then((res) => res.blob());
      }
      return null;
    })
  );
  console.log("audioBlobs", audioBlobs);
  console.log("imageBlobs", imageBlobs);
  await Promise.all([
    uploadBlobToS3(audioBlobs[0], `cache/suno/mp3s/${hash}-1.mp3`),
    uploadBlobToS3(audioBlobs[1], `cache/suno/mp3s/${hash}-2.mp3`),
    imageBlobs[0] &&
      uploadBlobToS3(imageBlobs[0], `cache/suno/images/${hash}-1.png`),
    imageBlobs[1] &&
      uploadBlobToS3(imageBlobs[1], `cache/suno/images/${hash}-2.png`),
  ]);
  console.log("audio uploaded");
};
