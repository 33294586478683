import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { sampleCorrelation, variance } from 'simple-statistics'
import { useGeneralInfoContext } from '../../GeneralInfoContext'
import { MajorEventType } from '../../utils/sentimentAnalysis'
import CombinedFrequencyChart from './CombinedFrequencyChart'
import CorrelationGraph from './CorrelationGraph'
import FrequencyChart from './FrequencyChart'
import MajorEventsTable from './MajorEventsTable'
import MetricCard from './MetricCard'
import SentimentChartDrillDown from './SentimentChartDrillDown'
import SentimentOverTimeChart from './SentimentOverTimeChart'
import VarianceChart from './VarianceChart'
import VolatilityChart from './VolatilityChart'

interface DrillDownSentimentProps {
  onClose: () => void
  chartData: any[]
  allMajorEvents: MajorEventType[]
}

const DrillDownSentiment: React.FC<DrillDownSentimentProps> = (props) => {
  const { onClose, chartData, allMajorEvents } = props
  const theme = useTheme()
  const { users } = useGeneralInfoContext()
  const [user1, user2] = users || []
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [selectedEventIndex, setSelectedEventIndex] = useState<number | null>(
    null
  )
  const componentRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<HTMLDivElement>(null)
  const [displayedCharts, setDisplayedCharts] = useState<string[]>([])

  console.log('DrillDownSentiment received allMajorEvents:', allMajorEvents)

  useEffect(() => {
    if (chartData.length > 0) {
      setIsLoading(false)
    }
  }, [chartData])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { user1Variance, user2Variance, correlation } = useMemo(() => {
    const user1Data = chartData.map((item) => item.X_sentiment)
    const user2Data = chartData.map((item) => item.Z_sentiment)

    const user1Variance = user1Data.length > 1 ? variance(user1Data) : 0
    const user2Variance = user2Data.length > 1 ? variance(user2Data) : 0
    const correlation =
      user1Data.length > 1 && user2Data.length > 1
        ? sampleCorrelation(user1Data, user2Data)
        : 0

    return { user1Variance, user2Variance, correlation }
  }, [chartData])

  const { user1Frequency, user2Frequency, maxFrequency } = useMemo(() => {
    const user1Frequency = new Array(21).fill(0)
    const user2Frequency = new Array(21).fill(0)

    chartData.forEach((item) => {
      const user1Score = Math.round(item.X_sentiment) + 10
      const user2Score = Math.round(item.Z_sentiment) + 10
      user1Frequency[user1Score]++
      user2Frequency[user2Score]++
    })

    const maxFrequency = Math.max(...user1Frequency, ...user2Frequency)

    return { user1Frequency, user2Frequency, maxFrequency }
  }, [chartData])

  const handleEventClick = (event: any, index: number) => {
    console.log('Event clicked:', event)
    setSelectedEventIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  const scrollToChart = () => {
    if (chartRef.current) {
      chartRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node)
      ) {
        setSelectedEventIndex(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleChartDisplay = (chartType: string) => {
    if (chartType === 'correlation') {
      setDisplayedCharts((prev) =>
        prev.includes('correlation') ? [] : ['correlation']
      )
    } else {
      setDisplayedCharts((prev) => {
        if (prev.includes(chartType)) {
          return prev.filter((chart) => chart !== chartType)
        } else if (prev.length === 1 && prev[0] !== 'correlation') {
          return [...prev, chartType]
        } else {
          return [chartType]
        }
      })
    }
  }

  const correlationData = useMemo(() => {
    return chartData.map((item) => ({
      x: item.X_sentiment,
      y: item.Z_sentiment,
    }))
  }, [chartData])

  const varianceData = useMemo(() => {
    return chartData.map((item) => ({
      weekStart: item.weekStart,
      X_sentiment: item.X_sentiment,
      Z_sentiment: item.Z_sentiment,
    }))
  }, [chartData])

  return (
    <Container maxWidth="lg" ref={componentRef}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={onClose}
          variant="outlined"
          color="inherit"
          sx={{
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          }}
        >
          Back to Dashboard
        </Button>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            borderRadius: 2,
            width: '30%',
            mx: 'auto',
            background: `linear-gradient(360deg, #ffffff, #e6f0ff)`,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            sx={{ textAlign: 'center', width: '100%' }}
          >
            Sentiment Analysis{' '}
            <Box
              component="span"
              sx={{
                background: `linear-gradient(90deg, ${theme.palette.primary.main}, #00C4FF, #60A5FA)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontFamily: "'Comfortaa', sans-serif",
                fontWeight: 'bold',
              }}
            >
              Pro{' '}
            </Box>
          </Typography>
        </Paper>
      </Box>
      {isLoading ? (
        <Typography variant="body1" sx={{ mt: 3 }}>
          Loading sentiment analysis data...
        </Typography>
      ) : error ? (
        <Typography variant="body1" color="error" sx={{ mt: 3 }}>
          {error}
        </Typography>
      ) : chartData.length > 0 ? (
        <>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <MetricCard
              title={`${user1} Sentiment Variance`}
              value={user1Variance}
              explanation="Higher variance indicates more fluctuation in sentiment."
              onClick={() => handleChartDisplay(user1)}
            />
            <MetricCard
              title="Sentiment Correlation"
              value={correlation}
              explanation="1 indicates perfect positive correlation, -1 perfect negative, and 0 no correlation."
              onClick={() => handleChartDisplay('correlation')}
            />
            <MetricCard
              title={`${user2} Sentiment Variance`}
              value={user2Variance}
              explanation="Higher variance indicates more fluctuation in sentiment."
              onClick={() => handleChartDisplay(user2)}
            />
          </Grid>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            {displayedCharts.includes('correlation') && (
              <Grid item xs={12}>
                <CorrelationGraph
                  data={correlationData}
                  user1={user1}
                  user2={user2}
                />
              </Grid>
            )}
            {displayedCharts.includes(user1) && (
              <Grid item xs={displayedCharts.length === 1 ? 12 : 6}>
                <VarianceChart
                  data={varianceData}
                  user={user1}
                  userKey="X_sentiment"
                  color={theme.palette.primary.main}
                />
              </Grid>
            )}
            {displayedCharts.includes(user2) && (
              <Grid item xs={displayedCharts.length === 1 ? 12 : 6}>
                <VarianceChart
                  data={varianceData}
                  user={user2}
                  userKey="Z_sentiment"
                  color={theme.palette.secondary.main}
                />
              </Grid>
            )}
          </Grid>
          <Box sx={{ mt: 6 }}>
            <MajorEventsTable
              majorEvents={Array.isArray(allMajorEvents) ? allMajorEvents : []}
              onEventClick={handleEventClick}
              scrollToChart={scrollToChart}
            />
          </Box>
          <Box sx={{ mt: 6 }} ref={chartRef}>
            <SentimentChartDrillDown
              chartData={chartData}
              selectedEventIndex={selectedEventIndex}
            />
          </Box>
          <Grid container spacing={3} sx={{ mt: 6 }}>
            <CombinedFrequencyChart
              user1Frequency={user1Frequency}
              user2Frequency={user2Frequency}
              user1={user1}
              user2={user2}
              maxFrequency={maxFrequency}
              theme={theme}
            />
            <FrequencyChart
              data={user1Frequency}
              color={theme.palette.primary.main}
              userName={user1}
              maxFrequency={maxFrequency}
              theme={theme}
            />
            <FrequencyChart
              data={user2Frequency}
              color={theme.palette.secondary.main}
              userName={user2}
              maxFrequency={maxFrequency}
              theme={theme}
            />
          </Grid>
          <Grid container spacing={3} sx={{ mt: 6 }}>
            <SentimentOverTimeChart
              chartData={chartData}
              user1={user1}
              user2={user2}
              theme={theme}
              isMobile={isMobile}
            />
            <VolatilityChart
              chartData={chartData}
              user1={user1}
              user2={user2}
              theme={theme}
              isMobile={isMobile}
            />
          </Grid>
        </>
      ) : (
        <Typography variant="body1" sx={{ mt: 3 }}>
          No sentiment analysis data available.
        </Typography>
      )}
    </Container>
  )
}
export default DrillDownSentiment
